import { IMeta } from 'common/common.interface'
import { IQuotations } from './quotations.interface'

export const QuotationDataDummy: IQuotations[] = [
    {
        quotationNo: '000001041',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '03-05-2024',
        endDate: '17-05-2024',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'approved',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001042',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '15-06-2024',
        endDate: '29-06-2024',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'waiting',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001043',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '21-07-2024',
        endDate: '05-08-2024',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'declined',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001044',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '09-08-2024',
        endDate: '24-09-2024',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'approved',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001045',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '28-09-2024',
        endDate: '13-11-2024',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'waiting',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001046',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '10-10-2024',
        endDate: '25-11-2024',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'declined',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001047',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '05-11-2024',
        endDate: '20-12-2024',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'approved',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001048',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '17-12-2024',
        endDate: '02-02-2025',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'waiting',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001049',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '22-01-2025',
        endDate: '25-03-2025',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'declined',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001050',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '14-02-2025',
        endDate: '16-04-2025',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'approved',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001051',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '26-03-2025',
        endDate: '28-05-2025',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'waiting',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001052',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '08-04-2025',
        endDate: '09-07-2025',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'declined',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001053',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '19-05-2025',
        endDate: '20-08-2025',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'approved',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001054',
        companyName: 'VNM - OneByOne Logistical',
        startDate: '30-06-2025',
        endDate: '27-08-2025',
        oneOffShipmentOrigin: 'JPYOK - Yokohama, 14, Japan',
        oneOffShipmentDestination: 'USLAX - Los Angeles, CA',
        totalLocalSelfAmount: '00000',
        status: 'waiting',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
    {
        quotationNo: '000001055',
        companyName: 'GBR - OneByOne Logistical',
        startDate: '05-07-2025',
        endDate: '25-08-2025',
        oneOffShipmentOrigin: 'AUMEL - Melbourne, VIC, Australia',
        oneOffShipmentDestination: 'JPTYO - Tokyo, 13, Japan',
        totalLocalSelfAmount: '00000',
        status: 'declined',

        //Quote Details
        transportMode: 'Sea Freight',
        containerMode: 'Sea Freight (FCL and LCL)',
        serviceLevel: 'Standard',
        incoterm: 'Ex Works',
        weight: '0.000',
        weightUOM: 'KG',
        volume: '0.000',
        volumeUOM: 'M3',
        valueOfGoods: '0.00',
        valueOfGoodsCurrency: 'AUD',
        insuranceValue: '0.00',
        insuranceValueCurrency: 'AUD',

        //Containers
        containers: {
            count: '1',
            containerType: '20GP',
        },
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
