export default function Detail({ className, data }: any) {
    return (
        <div className={`grid ${className}`}>
            {data?.map((item: any, index: number) => {
                return (
                    <div key={index} className="">
                        <div className="grid grid-cols-2 grid-rows-1 whitespace-nowrap text-gray-400 text-xs font-bold font-['Basis Grotesque Arabic Pro']">
                            <div className="text-gray-400 lg:text-[12px] md:text-xs font-normal font-['Basis Grotesque Arabic Pro']">
                                {item.mode}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 grid-row-1">
                            <div className="col-span-3 text-blue-950 lg:text-[14px] md:text-xl font-bold font-['Basis Grotesque Arabic Pro'] gap-2 whitespace-nowrap">
                                {item.detail}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
