import Dropdown from "components/dropdown/dropdown.component"
import Input from "components/input/input.component"
import { FormikProvider } from "formik"
import useSetupSiteCreate from "../setup-site-create.service"

const BasicInformation = () => {
    const {formikModule} = useSetupSiteCreate()
    return (
        <div className="flex">
            <FormikProvider value={formikModule}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        formikModule.handleSubmit()
                    }}
                    className="flex-col w-full p-4 space-y-4 h-[calc(100vh-284px)] overflow-auto"
                >
                    <div className="flex w-full gap-x-4">
                        <div className="flex-col w-1/4 h-full gap-y-[10px]">
                            <div className="flex">
                                <span className="flex text-size-M font-bold">
                                    Site Info
                                </span>
                            </div>
                            <div className="flex">
                                <p className="flex text-size-S font-normal text-wrap">
                                    Provide essential details about your site, such as its name or identifier, for easy reference.
                                </p>
                            </div>
                        </div>
                        <div className="flex-col w-3/4 h-full space-y-4">
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="SITE CODE"
                                    placeholder="Input Code"
                                    required={true}
                                    type="text"
                                />
                                <Input 
                                    label="SITE ID"
                                    placeholder="Add Site ID"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="SITE Name"
                                    placeholder="Input Name"
                                    required={false}
                                    type="text"
                                />
                                <Input 
                                    label="LOCATION"
                                    placeholder="Add Location"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="GLN CODE"
                                    placeholder="Input GLN Code"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="DIVISION"
                                    placeholder="Input Division"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Dropdown 
                                    label="STOCKTAKE IN PROGRESS"
                                    placeholder="Select"
                                    options={[]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full gap-x-4">
                        <div className="flex-col w-1/4 h-full gap-y-[10px]">
                            <div className="flex">
                                <span className="flex text-size-M font-bold">
                                    Site Address Details
                                </span>
                            </div>
                            <div className="flex">
                                <p className="flex text-size-S font-normal text-wrap">
                                    Enter precise location information, including street address, city, state/province, postal/ZIP code, and country.
                                </p>
                            </div>
                        </div>
                        <div className="flex-col w-3/4 h-full space-y-4">
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="ADDRESS LINE 1"
                                    placeholder="Add Address 1"
                                    required={true}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="ADDRESS LINE 2"
                                    placeholder="Add Address 2"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Dropdown 
                                    label="CITY"
                                    placeholder="Select City"
                                    options={[{label: "Majalengka", value: "majalengka"}]}
                                />
                                <Dropdown 
                                    label="STATE"
                                    placeholder="Select State"
                                    options={[{label: "Jawa Timur", value: "jawaTimur"}]}
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Dropdown 
                                    label="COUNTRY"
                                    placeholder="Select Country"
                                    options={[{label: "Indonesia", value: "indonesia"}]}
                                />
                                <Input 
                                    label="POSTCODE"
                                    placeholder="Input Postcode"
                                    required={false}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full gap-x-4">
                        <div className="flex-col w-1/4 h-full space-y-4">
                            <div className="flex">
                                <span className="flex text-size-M font-bold">
                                    Site Contact
                                </span>
                            </div>
                            <div className="flex">
                                <p className="flex text-size-S font-normal text-wrap">
                                    Specify the primary contact person's name, phone number, and email address for seamless communication and coordination.
                                </p>
                            </div>
                        </div>
                        <div className="flex-col w-3/4 h-full space-y-4">
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="PHONE NO"
                                    placeholder="Input Phone Number"
                                    required={true}
                                    type="number"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="FAX NO"
                                    placeholder="Input Fax Number"
                                    required={true}
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </div>
    )
}

export default BasicInformation