import { IRoute } from 'common/common.interface'
import Settings from 'pages/common/settings/settings.component'
import { DashboardInProgress } from 'pages/common/work-in-progress/work-in-progress.component'
import Bookings from 'pages/limajari/bookings/bookings.component'
import CreateOrganisation from 'pages/limajari/organisation/create-organisation/create-organisation.component'
import ContactList from 'pages/limajari/contact-list/contact-list.component'
import OrganisationDetail from 'pages/limajari/organisation/organisation-details/organisation-details.component'
import Organisation from 'pages/limajari/organisation/organisation.component'
import CreateBooking from 'pages/limajari/shipments/create-booking/create-booking.component'
import Shipments from 'pages/limajari/shipments/shipments.component'
import Quotes from 'pages/limajari/quotes/quotes.component'
import newQuote from 'pages/limajari/quotes/new-quote/new-quote.component'
import UserAccess from 'pages/common/user-acces/user-access.component'
import UserAccessDetail from 'pages/common/user-acces/user-access-detail/user-access-detail.component'
import UserAccessForm from 'pages/common/user-acces/user-access-form/user-access-form.component'

export const limajariRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
    },
    {
        path: '/quotes',
        text: 'Quotes',
        Content: Quotes,
        show: true,
        icon: 'ri-file-paper-line',
    },
    {
        path: '/quote-details',
        text: 'Detail Quote',
        Content: () => newQuote({ isDetailPage: true }),
    },
    {
        path: '/new-quote',
        text: 'New Quote',
        Content: () => newQuote({ isDetailPage: false }),
    },
    {
        path: 'forwarding',
        text: 'Forwarding',
        show: true,
        icon: 'ri-truck-line',
        expandable: true,
        sub: [
            {
                path: '/bookings',
                text: 'Bookings',
                Content: Bookings,
                parentId: '4',
                show: true,
            },
            {
                path: '/shipments',
                text: 'Shipments',
                Content: Shipments,
                show: true,
            },
        ],
    },
    {
        path: 'create-booking',
        text: 'Create Booking',
        Content: CreateBooking,
    },
    {
        path: 'organisation',
        text: 'Organisation',
        show: true,
        icon: 'ri-contacts-book-2-line',
        expandable: true,
        sub: [
            {
                path: '/organisation',
                text: 'All Organisation',
                Content: Organisation,
                show: true,
            },
            {
                path: '/contact-list',
                text: 'Contact List',
                Content: ContactList,
                show: true,
            },
        ],
    },
    {
        path: 'organisation-detail',
        text: 'Organisation Details',
        Content: OrganisationDetail,
    },
    {
        path: 'create-organisation',
        text: 'Create Organisation ',
        Content: CreateOrganisation,
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        Content: UserAccess,
        show: true,
        icon: 'ri-user-line',
    },
    {
        path: '/manage-users/details/:id',
        text: 'User Details',
        Content: UserAccessDetail,
    },
    // {
    //     path: '/profile',
    //     text: 'Profile',
    //     Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
    // },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => UserAccessForm({ isNew: true, isProfilePage: false }),
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: false }),
    },
    {
        path: '/settings',
        text: 'Settings',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
    },
]
