import { IRoute } from 'common/common.interface'
import Settings from 'pages/common/settings/settings.component'
import Bookings from 'pages/poc/bookings/bookings.component'
import CreateBooking from 'pages/poc/bookings/create-booking/create-booking.component'
import ContactList from 'pages/poc/contact-list/contact-list.component'
import Containers from 'pages/poc/containers/containers.component'
import { Dashboard } from 'pages/poc/dashboard/dashboard.component'
import ManageUserDetails from 'pages/poc/manage-users/manage-user-details/manage-user-details.component'
import ManageUserForm from 'pages/poc/manage-users/manage-user-form/manage-user-form.component'
import ManageUsers from 'pages/poc/manage-users/manage-users.component'
import CreateOrganisation from 'pages/poc/organisation/create-organisation/create-organisation.component'
import OrganisationDetail from 'pages/poc/organisation/organisation-details/organisation-details.component'
import Organisation from 'pages/poc/organisation/organisation.component'
import QuotationForm from 'pages/poc/quotation/create-and-details/quotation-form.component'
import Quotations from 'pages/poc/quotation/quotations.component'
import CreateSite from 'pages/poc/setup-site/setup-site-create/setup-site-create.component'
import SetupSiteForm from 'pages/poc/setup-site/setup-site-form/setup-site-form.component'
import SetupSite from 'pages/poc/setup-site/setup-site.component'
import ShipmentDetails from 'pages/poc/shipments/shipments-detail/shipments-details.components'
import Shipments from 'pages/poc/shipments/shipments.component'

export const pocRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: Dashboard,
        show: false,
        icon: 'ri-dashboard-line',
        parentId: '1',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: Dashboard,
        show: true,
        icon: 'ri-dashboard-line',
        parentId: '2',
    },
    {
        path: 'forwarding',
        text: 'Forwarding',
        show: true,
        icon: 'ri-truck-line',
        parentId: '3',
        expandable: true,
        sub: [
            {
                path: '/quotations',
                text: 'Quotes',
                Content: Quotations,
                parentId: '4',
                show: true,
            },
            {
                path: '/bookings',
                text: 'Bookings',
                Content: Bookings,
                parentId: '6',
                show: true,
            },
            {
                path: '/shipments',
                text: 'Shipments',
                Content: Shipments,
                parentId: '6',
                show: true,
            },
            {
                path: '/containers',
                text: 'Containers',
                Content: Containers,
                parentId: '7',
                show: true,
            },
        ],
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        parentId: '16',
        Content: ManageUsers,
        show: true,
        icon: 'ri-shield-user-line',
    },
    {
        path: 'manage-users/details/:id',
        text: 'User Details',
        Content: ManageUserDetails,
    },
    {
        path: '/profile',
        text: 'Profile',
        Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
    },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => ManageUserForm({ isNew: true, isProfilePage: false }),
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => ManageUserForm({ isNew: false, isProfilePage: false }),
    },
    {
        path: 'create-quotation',
        text: 'Create Quotation',
        Content: () => QuotationForm({ isNew: true }),
    },
    {
        path: 'quotation-details/:id',
        text: 'Quotation Details',
        Content: () => QuotationForm({ isNew: false }),
    },
    {
        path: 'create-booking',
        text: 'Create Booking',
        Content: CreateBooking,
    },
    {
        path: 'organisation',
        text: 'Organisation',
        show: true,
        icon: 'ri-contacts-book-2-line',
        expandable: true,
        sub: [
            {
                path: '/organisation',
                text: 'All Organisation',
                Content: Organisation,
                show: true,
            },
            {
                path: '/contact-list',
                text: 'Contact List',
                Content: ContactList,
                show: true,
            },
        ],
    },
    {
        path: 'organisation-detail',
        text: 'Organisation Details',
        Content: OrganisationDetail,
    },
    {
        path: 'shipments-detail/:id',
        parentId: '17',
        text: 'Shipments Detail',
        Content: ShipmentDetails,
    },
    {
        path: 'create-organisation',
        text: 'Create Organisation ',
        Content: CreateOrganisation,
    },

    {
        path: 'setup',
        text: 'Warehouse Setup',
        parentId: '4',
        show: true,
        icon: 'ri-settings-3-line',
        expandable: true,
        sub: [
            {
                path: 'site',
                text: 'Site',
                Content: SetupSite,
                parentId: '5',
                show: true,
                icon: 'ri-account-circle-line',
            },
            //   {
            //     path: "client",
            //     title: "Set Up: Client",
            //     text: "Client",
            //     Content: SetupClients,
            //     parentId: "4.3",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "customer",
            //     title: "Set Up: Customer",
            //     text: "Customer",
            //     Content: SetupCustomers,
            //     parentId: "4.4",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "supplier",
            //     title: "Set Up: Supplier",
            //     text: "Supplier",
            //     Content: SetupSuppliers,
            //     parentId: "4.5",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "product",
            //     title: "Set Up: Product",
            //     text: "Product",
            //     Content: SetupProduct,
            //     parentId: "4.4",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "zones",
            //     title: "Set Up: Zones",
            //     text: "Zones",
            //     Content: SetupZones,
            //     parentId: "4.5",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "packtypes",
            //     title: "Set Up: Product",
            //     text: "PackTypes",
            //     Content: SetupPacktypes,
            //     parentId: "4.6",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "groupProduct",
            //     title: "Set Up: Product Group",
            //     text: "Product Group",
            //     Content: SetupProductGroup,
            //     parentId: "4.5",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "sizeLocation",
            //     title: "Set Up: Location Sizes",
            //     text: "Location Sizes",
            //     Content: SetupLocationSizes,
            //     parentId: "4.7",
            //     show: true,
            //     isSubMenu: true,
            //   },
            //   {
            //     path: "typeLocation",
            //     title: "Set Up: Location Types",
            //     text: "Location Types",
            //     Content: SetupLocationTypes,
            //     parentId: "4.8",
            //     show: true,
            //     isSubMenu: true,
            //   },
        ],
    },

    {
        path: 'site-create/',
        text: 'Site Create',
        Content: CreateSite,
    },
    {
        path: 'site-details/:id',
        text: 'Site Details',
        Content: SetupSiteForm,
    },
    {
        path: '/settings',
        text: 'Settings',
        parentId: '16',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
    },
]
