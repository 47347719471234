/* eslint-disable no-unused-vars */
import Button from 'components/button/button.component'
import { IButton } from 'components/button/button.interface'
// import { IButton } from 'components/button/button.interface'
import Overlay from 'components/overlay/overlay.component'
import useOverlay from 'components/overlay/overlay.service'
// import { useNavigate } from 'react-router-dom'

const RowOptionOverlay = <T,>({
    rowOptionComponent,
    data,
}: {
    data: T
    rowOptionComponent: (data: T, closeHandling: () => void) => JSX.Element
}) => {
    const overlayService = useOverlay()
    return (
        <>
            <Button
                onClick={() => {
                    overlayService?.openOverlayHandling()
                }}
                icon="ri-more-2-line"
                className="border-none w-btnIconWidth"
            />

            <Overlay
                isOverlayOpen={overlayService?.isOverlayOpen}
                className="!w-[11.1rem] p-1 !absolute"
                overlayRef={overlayService.overlayRef}
            >
                <div>
                    {rowOptionComponent(
                        data,
                        overlayService.closeOverlayHandling,
                    )}
                </div>
            </Overlay>
        </>
    )
}

export default RowOptionOverlay
