import React from 'react';

interface IEstimationInfo {
    initialCountryCode: string;
    initialCountryName: string;
    destinationCountryCode: string;
    destinationCountryName: string;
    ETD: string;
    ETA: string;
    iconMap?: boolean;
}

const EstimationInfo: React.FC<IEstimationInfo> = ({
    initialCountryName,
    initialCountryCode,
    destinationCountryName,
    destinationCountryCode,
    ETD,
    ETA,
    iconMap
}) => {

    const originImgCode = initialCountryCode.slice(0, 2).toLowerCase();
    const destinationImgCode = destinationCountryCode.slice(0, 2).toLowerCase();
    const originImg = `https://flagcdn.com/${originImgCode}.svg`;
    const destinationImg = `https://flagcdn.com/${destinationImgCode.toLowerCase()}.svg`
    return (
        <div className='mt-2 flex '>
            <div className='flex items-center' style={{ lineHeight: '14px' }}>
                {iconMap && <i className="ri-map-pin-line mr-1" />}
                <img
                    src={originImg}
                    className='w-[32px]'
                    alt={initialCountryName} />
                <div className='text-[14px] items-center ml-1'>
                    <div className='font-bold'>{initialCountryCode}</div>
                    <div>{initialCountryName}</div>
                </div>
            </div>
            <div className='flex-grow mx-2'>
                <div className='flex justify-between border-b themes-border-extra-red-v4'>
                    <div className='text-[12px] themes-text-gray-v6'>
                        <div>ETD</div>
                        <div>{ETD}</div>
                    </div>
                    <div className='text-[12px] themes-text-gray-v6'>
                        <div>ETA</div>
                        <div>{ETA}</div>
                    </div>
                </div>
            </div>
            <div className='flex items-center' style={{ lineHeight: '14px' }}>
                {iconMap && <i className="ri-map-pin-line themes-text-brand-v5 mr-1" />}
                <img
                    src={destinationImg}
                    className='w-[32px]'
                    alt={destinationCountryName} />
                <div className='text-[14px] items-center ml-1'>
                    <div className='font-bold'>{destinationCountryCode}</div>
                    <div>{destinationCountryName}</div>
                </div>
            </div>
        </div>
    );
}

export default EstimationInfo;
