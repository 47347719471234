import { IMeta } from 'common/common.interface'
import { IContainer } from './containers.interface'

export const ContainerDataDummy: IContainer[] = [
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "SEA",
        initialCountryCode: "USNYC",
        initialCountryName: "New York",
        destinationCountryCode: "THBKK",
        destinationCountryName: "Bangkok",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "AIR",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "LAND",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]

    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "AIR",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "SEA",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "LAND",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "SEA",
        initialCountryCode: "USNYC",
        initialCountryName: "New York",
        destinationCountryCode: "THBKK",
        destinationCountryName: "Bangkok",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "AIR",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "LAND",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "AIR",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "SEA",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    },
    {
        idShipment: "CTBK1234564",
        description: "20GP on CMA CGM PANAMA, EVER PEARL",
        typeTransport: "LAND",
        initialCountryCode: "THBKK",
        initialCountryName: "Bangkok",
        destinationCountryCode: "USNYC",
        destinationCountryName: "New York",
        ETD: "07 MAR 24",
        ETA: "15 APR 24",
        detail: {
            containerType: "40HC",
            containerMode: "FCL",
            shipmentID: "STHOSE2300002203",
            weight: "4980 KG",
            carrier: "OCEAN NETWORK EXPRESS",
            vessel: "APL OREGON, MOL EARNEST, TBA",
            typeTransportCode: ", 087W, 348S",
            method: "NON",
            lastEdit: "24-Nov-23 10:32",
        },
        track: [
            {
                label: 'Arrival',
                desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
                time: 'Aug 1, 2023 00:00'
            },
            {
                label: 'Departure',
                desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
                time: 'Jul 28, 2023 00:00'
            },
            {
                label: 'All Export Documents Received',
                time: 'Mar 2, 2023 19:40'
            },
            {
                label: 'All Import Documents Received',
                time: 'Mar 2, 2023 19:40'
            }
        ]
    }
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
