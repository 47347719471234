import TabMaster from 'components/tab-master/tab-master.component'
import Table from 'components/table/table.component'
import { IQuotations } from './quotations.interface'
import {
    QuotationStatusHeader,
    QuotationSummaryHeader,
} from './quotations.static'
import useQuotations from './quotations.service'
import { metaDummy, QuotationDataDummy } from './quotations.dummy'
import QuotationFilterOverlay from './components/quotation-filter-overlay.component'
import QuotationFilter from './components/quotation-filter.component'

const Quotations = () => {
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        navigate,
        setTabFilter,
    } = useQuotations()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={QuotationStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Quotation No. (min 3 char)'}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <QuotationFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () => {},
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-quotation')
                        },
                        label: 'NEW QUOTATION',
                        className: '!text-size-S',
                    }}
                />

                <Table<IQuotations>
                    headers={QuotationSummaryHeader}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={function (data): void {
                        const id = data.quotationNo
                        navigate('/quotation-details/' + id)
                    }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    components={<QuotationFilter isGenerateReport={true} />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={QuotationDataDummy}
                    GenerateReportHeaders={QuotationSummaryHeader}
                    removeFilter={() => {}}
                />
            </div>
        </div>
    )
}

export default Quotations
