import Modal from 'components/modal/modal.component'
import { IUseModal } from 'components/modal/modal.service'
import Button from 'components/button/button.component'

const SuspendUserModal = ({
    isActive,
    modalService,
    data,
    onSubmit,
}: {
    isActive: boolean
    data: any | undefined
    modalService: IUseModal
    onSubmit: () => void
}) => {
    const label = isActive
        ? 'Mark Contact as Non Active'
        : 'Mark Contact as Active'
    const subs = isActive
        ? `Are you sure you want to mark this “${data?.name}” as non-active? \n
This action means you will no longer be able to add this Contact to any activity.`
        : `Are you sure you want to reactive “${data?.name}”? \n
This will restore its active status.`
    return (
        //for JPL
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3">
            <div className="flex flex-col gap-4 justify-content">
                <div className="font-bold text-size-L">{label}</div>
                <div className="border-b border-gray-40"></div>
                <div className="themes-text-gray-v5">{subs}</div>
                <div className="border-b border-gray-40"></div>
                <div className="flex justify-content ">
                    <div className="flex-grow">
                        <Button
                            label="BACK"
                            variant="default"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            label="YES"
                            variant="red"
                            onClick={() => {
                                modalService.closeModalHandling()
                                onSubmit()
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SuspendUserModal
