import { IQuote } from './quotes.interface'

export const quotesDummy: IQuote[] = [
    {
        mode: 'air',
        quoteId: 'Q123456',
        client: 'ABC Corp',
        origin: 'New York, USA',
        destination: 'London, UK',
        containerMode: 'LCL',
        currency: 'USD',
        volumetricWeight: '150 kg',
        actualWeight: '120 kg',
        branch: 'NYC Branch',
        createdBy: 'John Doe',
        dateCreated: '2024-01-10',
        lastUpdated: '2024-01-12',
    },
    {
        mode: 'sea',
        quoteId: 'Q654321',
        client: 'XYZ Ltd',
        origin: 'Shanghai, China',
        destination: 'Los Angeles, USA',
        containerMode: 'FCL',
        currency: 'CNY',
        volumetricWeight: '300 kg',
        actualWeight: '280 kg',
        branch: 'Shanghai Branch',
        createdBy: 'Jane Smith',
        dateCreated: '2024-02-15',
        lastUpdated: '2024-02-20',
    },
    {
        mode: 'air',
        quoteId: 'Q789012',
        client: 'MegaCorp',
        origin: 'Berlin, Germany',
        destination: 'Paris, France',
        containerMode: 'Truck',
        currency: 'EUR',
        volumetricWeight: '100 kg',
        actualWeight: '95 kg',
        branch: 'Berlin Branch',
        createdBy: 'Hans Müller',
        dateCreated: '2024-03-05',
        lastUpdated: '2024-03-07',
    },
    {
        mode: 'air',
        quoteId: 'Q345678',
        client: 'FastShipping Inc',
        origin: 'Sydney, Australia',
        destination: 'Tokyo, Japan',
        containerMode: 'LCL',
        currency: 'AUD',
        volumetricWeight: '200 kg',
        actualWeight: '180 kg',
        branch: 'Sydney Branch',
        createdBy: 'Alice Johnson',
        dateCreated: '2024-04-01',
        lastUpdated: '2024-04-03',
    },
    {
        mode: 'sea',
        quoteId: 'Q901234',
        client: 'Oceanic Traders',
        origin: 'Mumbai, India',
        destination: 'Cape Town, South Africa',
        containerMode: 'FCL',
        currency: 'INR',
        volumetricWeight: '400 kg',
        actualWeight: '350 kg',
        branch: 'Mumbai Branch',
        createdBy: 'Rahul Singh',
        dateCreated: '2024-05-20',
        lastUpdated: '2024-05-25',
    },
]
