export const getStatusClass = (status: string): string => {
    let retClass: string
    switch (status) {
        case 'Open':
        case 'Confirmed':
        case 'Active':
            retClass = 'themes-status-brand'
            break
        case 'In Progress':
            retClass = 'themes-status-yellow'

            break
        case 'Canceled':
        case 'Non Active':
            retClass = 'themes-status-red'

            break

        default:
            retClass = 'themes-status-gray'
            break
    }
    return retClass
}
