import { useState } from "react"

const useTransportationSelection = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    return {
        activeIndex,
        setActiveIndex
    }
}
export default useTransportationSelection