import SimpleTable from 'components/simple-table/simple-table.component'
import { containerHeader } from '../shipments-details.static'
import { IContainer } from 'pages/poc/containers/containers.interface'

const Containers = (data: any) => {
    console.log(data)
    const datas = [
        {
            containerNo: 'CNT123456',
            shipmentNo: 'SHP001',
            sealNo: 'SEAL987654',
            containerType: '40ft Standard',
            containerMode: 'Full',
            emptyReturnedOn: '2024-03-15',
            tareWeight: '5000 kg',
            goodWeight: '15000 kg',
            estDelivery: 'Truck',
            estReturn: '2024-03-25',
            actReturn: '2024-03-30',
            containerParkEmptyReturnGateIn: '2024-03-30',
        },
        {
            containerNo: 'CNT789012',
            shipmentNo: 'SHP002',
            sealNo: 'SEAL123456',
            containerType: '20ft Reefer',
            containerMode: 'Empty',
            emptyReturnedOn: '2024-03-20',
            tareWeight: '3000 kg',
            goodWeight: '0 kg',
            estDelivery: 'Rail',
            estReturn: '2024-04-05',
            actReturn: '2024-04-10',
            containerParkEmptyReturnGateIn: '2024-04-10',
        },
        {
            containerNo: 'CNT345678',
            shipmentNo: 'SHP003',
            sealNo: 'SEAL234567',
            containerType: '45ft High Cube',
            containerMode: 'Partial',
            emptyReturnedOn: '2024-03-25',
            tareWeight: '6000 kg',
            goodWeight: '10000 kg',
            estDelivery: 'Ship',
            estReturn: '2024-04-15',
            actReturn: '2024-04-20',
            containerParkEmptyReturnGateIn: '2024-04-20',
        },
    ]

    return (
        <SimpleTable<IContainer>
            headers={containerHeader}
            data={datas as unknown as IContainer[]}
            title={<>Container(s)</>}
        />
    )
}

export default Containers
