import { faker } from '@faker-js/faker'

export const totalShipmentMode = [
    {
        name: 'Mon',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Tue',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Wed',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Thu',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Fri',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Sat',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Now',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]

export const totalArrival = [
    {
        name: 'Jan',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Feb',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Mar',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Apr',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'May',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Jun',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Jul',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Aug',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Sep',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Oct',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Nov',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Dec',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
]

export const totalVolumeByMode = [
    {
        mode: 'SEA(FCL)',
        detail: '10 TEU',
    },
    {
        mode: 'SEA(LCL)',
        detail: '17 CBM',
    },
    {
        mode: 'RAIL(FCL)',
        detail: '3 FEU',
    },
    {
        mode: 'RAIL(LCL)',
        detail: '4 CBM',
    },
    {
        mode: 'AIR',
        detail: '6,7 TONS',
    },
    {
        mode: 'ROAD(LTL)',
        detail: '3',
    },
]

export const arrivalDetail = [
    {
        mode: 'Arrival Accuracy',
        detail1: '91%',
        detail2: '9%',
        increase2: false,
    },
    {
        mode: 'On Time Arrival',
        detail1: '3280',
        detail2: '+12',
        increase2: true,
    },
    {
        mode: 'Delayed Arrival',
        detail1: '10',
        detail2: '+2',
        increase2: true,
    },
]

export const news = [
    '35-Hour Rail Strike Announced in Germany',
    'Update: Red Sea & The Freight Market',
    'Lunar New Year IFB Office Closings',
    'IFB Poland X Polish Amp Football National Team',
    'The Situation in the Red Sea and Lunar New Year',
    'New Office Location in Guangzhou',
]

export const shipmentTrackingHeader = [
    {
        value: 'mode',
        label: 'Mode',
    },
    {
        value: 'id',
        label: 'No.',
    },
    {
        value: 'etd',
        label: 'ETD',
    },
    {
        value: 'eta',
        label: 'ETA',
    },
    {
        value: 'origin',
        label: 'Origin',
    },
    {
        value: 'destination',
        label: 'Destination',
    },
]

// tgl-bulan-thn
export function getShipmentTracking(): any {
    let data = []

    for (let i = 0; i < 2; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `THBKK`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 3; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `ID`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 10; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `THBKK`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 10; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `ID`,
            destination: `AUMEL`,
        })
    }
    return data
}
