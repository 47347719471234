/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { handleFeatureInDevelopment } from './quotes.static'
import { ITabItem } from 'components/tab/tab.interface'
import { IQuote } from './quotes.interface'
import { IButton } from 'components/button/button.interface'
import { useAppDispatch } from 'store'
import {
    quotesDataByStatusSelector,
    quotesDataSelector,
    setData,
    setDataByStatus,
    setSelectedStatus,
    setStatusTotal,
    statusSelectedSelector,
    statusTotalSelector,
} from './quotes.slice'
import { useSelector } from 'react-redux'
import { Toast } from 'components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import ButtonPreviewDocument from 'components/button-preview-document/button-preview-document.component'
import PreviewQuote from './new-quote/components/preview-quote.component'
import Button from 'components/button/button.component'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import RowOptionOverlay from './components/row-option-overlay.component'
// import { IButton } from 'components/button/button.interface'

const useQuotes = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const quotesData = useSelector(quotesDataSelector)
    const quotessDataByStatus = useSelector(quotesDataByStatusSelector)
    const statusTotal = useSelector(statusTotalSelector)
    const tabFilter = useSelector(statusSelectedSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [handlingData, setHandlingData] = useState<boolean>(false)

    // get data quotations
    useEffect(() => {
        loadData()
        loadStatus()
    }, [tabFilter, handlingData])

    useEffect(() => {
        generateTabItems()
    }, [statusTotal])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            const data =
                tabFilter.value === 'allQuotes'
                    ? quotesData
                    : quotesData.filter((x) => x.mode === tabFilter.value)
            dispatch(setDataByStatus(data))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadStatus = () => dispatch(setStatusTotal())

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            const searchData = quotesData.filter((quote) =>
                quote.quoteId.includes(values),
            )
            setDataByStatus(searchData)
        } else if (values.length <= 0) loadData()
    }

    const removeDataRow = (rowData: IQuote) => {
        const updateData = quotesData.filter(
            (item) => item.quoteId !== rowData.quoteId,
        )
        dispatch(setData(updateData))
        setHandlingData(!handlingData)
    }

    // generate tab Items
    const generateTabItems = () => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Quotes',
                totalData: statusTotal.allQuotes,
                key: 'status',
                value: 'allQuotes',
                className: 'brand',
            },
            {
                label: 'SEA',
                totalData: statusTotal.sea,
                key: 'status',
                value: 'sea',
                className: 'brand',
            },
            {
                label: 'AIR',
                totalData: statusTotal.air,
                key: 'status',
                value: 'air',
                className: 'brand',
            },
        ]

        setTabItems(tabItems)
    }

    const setTabFilter = (data: ITabItem) => dispatch(setSelectedStatus(data))

    const quoteHeaders: ISTColumn<IQuote>[] = [
        {
            label: 'Mode',
            accessor: 'mode',
            customBuild: (data) => {
                let iconMode: string
                let labelMode: string
                switch (data) {
                    case 'air':
                        iconMode = 'ri-plane-line'
                        labelMode = 'AIR'
                        break
                    case 'sea':
                        iconMode = 'ri-ship-line'
                        labelMode = 'SEA'
                        break
                    default:
                        iconMode = 'ri-plane-line'
                        labelMode = 'AIR'
                }

                return (
                    <div className="flex gap-2">
                        <i className={`${iconMode}`}></i> {labelMode}
                    </div>
                )
            },
        },
        {
            label: 'Quote ID',
            accessor: 'quoteId',
        },
        {
            label: 'Client',
            accessor: 'client',
        },
        {
            label: 'Origin',
            accessor: 'origin',
        },
        {
            label: 'Destination',
            accessor: 'destination',
        },
        {
            label: 'Container Mode',
            accessor: 'containerMode',
        },
        {
            label: 'Currency',
            accessor: 'currency',
        },
        {
            label: 'Volumetric Weight',
            accessor: 'volumetricWeight',
        },
        {
            label: 'Actual Weight',
            accessor: 'actualWeight',
        },
        {
            label: 'Branch',
            accessor: 'branch',
        },
        {
            label: 'Created By',
            accessor: 'createdBy',
        },
        {
            label: 'Date Created',
            accessor: 'dateCreated',
        },
        {
            label: 'Last Updated',
            accessor: 'lastUpdated',
        },
    ]

    // components
    const rowOptionComponent = (rowData: IQuote, closeHandling: () => void) => (
        <div className="w-full">
            <ButtonPreviewDocument
                onClick={() => {
                    closeHandling()
                }}
                icon="ri-download-2-line"
                label="Download Pdf"
                componentPdf={<PreviewQuote data={rowData} />}
                isPreview={false}
                className="!w-full border-none !px-0"
            />
            <Button
                onClick={() => {
                    removeDataRow(rowData)
                    Toast({
                        header: 'Quote removed successfully!',
                        message: `Removing quote: ${rowData.quoteId}`,
                        type: 'success',
                    })
                    closeHandling()
                }}
                className="!w-full border-none"
                variant={'red-inverse'}
                label="Remove"
                icon="ri-delete-bin-line"
            />
        </div>
    )

    return {
        setTabItems,
        setTabFilter,
        handleSearch,
        navigate,
        quoteHeaders,
        rowOptionComponent,
        quotessDataByStatus,
        tabItems,
        tabFilter,
        loading,
    }
}

export default useQuotes
