import {
    IServiceRate,
    IShippingRate,
} from 'pages/limajari/quotes/new-quote/new-quote.interface'
import * as Yup from 'yup'

export interface INewQuote {
    // quote details
    startDate: string
    documentValidityPeriod: string
    customerName: string

    // shipping details
    transportMode: string
    containerMode: string
    etd: string
    codeOriginPort: string
    originPort: string
    codeDestinationPort: string
    destinationPort: string

    currency: string
    weight: {
        qty?: string
        unit?: string
    }
    volume: {
        qty?: string
        unit?: string
    }

    // shipping rates
    shippingRates: IShippingRate

    // service rates
    serviceRates: IServiceRate[]
    importantNotice: string
}

export const newQuoteValidation = Yup.object({
    startDate: Yup.string().required('Fuil Name required'),
})

export const newQuoteInitialValue: INewQuote = {
    startDate: '',
    documentValidityPeriod: '',
    customerName: '',

    // shipping details
    transportMode: '',
    containerMode: '',
    etd: '',
    codeOriginPort: '',
    originPort: '',
    destinationPort: '',
    codeDestinationPort: '',
    currency: '',
    weight: {
        qty: '',
        unit: '',
    },
    volume: {
        qty: '',
        unit: '',
    },
    shippingRates: {
        shippingMode: '',
        price: '',
        unit: '',
        qty: '',
        total: '',
    },
    serviceRates: [
        {
            title: 'Handling',
            price: '40.000',
            unit: 'per cbm',
            qty: '1.00',
            total: '40.000',
            id: '1',
        },
        {
            title: 'Packing Wooden Crate',
            price: '40.000',
            unit: 'per cbm',
            qty: '1.00',
            total: '40.000',
            id: '2',
        },
    ],
    importantNotice: '',
}
