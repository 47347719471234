/* eslint-disable no-unused-vars */
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { useState } from 'react'

const Pills = <T,>({
    items,
    onChange,
    variantUnActive = 'white',
}: {
    items: ISTColumn<T>[]
    onChange: (data: ISTColumn<T>) => void
    variantUnActive?: 'white' | 'gray'
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const bgVariantUnActive =
        variantUnActive === 'white' ? 'themes-bg-white' : 'themes-bg-gray-v2'
    return (
        <div
            className={`pills overflow-auto`}
            style={{ scrollbarWidth: 'none' }}
        >
            <div className="flex gap-x-2 w-max">
                {items?.map((x: any, idx: number) => {
                    const isActive =
                        activeIndex === idx
                            ? 'themes-bg-brand-v5 themes-text-white'
                            : bgVariantUnActive
                    return (
                        <div
                            key={`pill-${idx}`}
                            className={`${isActive} p-2 cursor-pointer rounded-full z-[0]`}
                            onClick={() => {
                                setActiveIndex(idx)
                                onChange(x)
                            }}
                        >
                            {x?.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Pills
