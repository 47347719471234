/* eslint-disable no-unused-vars */
import SimpleTable from 'components/simple-table/simple-table.component'
import { IEDocumentations } from '../organisation-details.interface'
import { eDocumentationHeaders } from '../organisation-details.static'
import PreviewDocument from 'components/preview-document/preview-document.component'
import Button from 'components/button/button.component'
import { handleFeatureInDevelopment } from '../../organisation.static'

const EDocumentations = ({
    data,
    detailsData,
    setDetailsData,
}: {
    data: IEDocumentations[]
    detailsData: IEDocumentations
    setDetailsData: (data: IEDocumentations) => void
}) => {
    return (
        <div className="flex gap-x-2">
            <div className="w-3/4 h-[calc(100vh-15.5rem)]">
                <SimpleTable<IEDocumentations>
                    headers={eDocumentationHeaders}
                    data={data}
                    isAddRow={true}
                    addRowLabel="Add New Documents"
                    onRowClick={setDetailsData}
                    addRowClick={() => {
                        handleFeatureInDevelopment()
                    }}
                    customBuildParam4={() => ({
                        func1: () => handleFeatureInDevelopment(),
                        func2: () => handleFeatureInDevelopment(),
                    })}
                    variant="gray"
                />
            </div>
            <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-3 flex justify-between">
                    <div>Preview</div>
                    <div className="flex gap-3">
                        <Button
                            onClick={() => {
                                handleFeatureInDevelopment()
                            }}
                            icon="ri-download-2-line"
                            className="w-[55px] !h-[28.8px]"
                        />
                        <Button
                            onClick={() => {
                                handleFeatureInDevelopment()
                            }}
                            icon="ri-mail-send-line"
                            className="w-[55px] !h-[28.8px]"
                        />
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="p-3 overflow-auto h-full">
                    <PreviewDocument fileUrl={detailsData.url} />
                </div>
            </div>
        </div>
    )
}

export default EDocumentations
