import { useModal } from "components/modal/modal.service"
import useOverlay from "components/overlay/overlay.service"
import { ITabItem } from "components/tab/tab.interface"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ManageUsersDataDummy } from "./manage-users.dummy"

const useManageUsers = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // state
    const [data, setData] = useState(ManageUsersDataDummy)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'All Users',
        totalData: ManageUsersDataDummy.length,
        key: 'status',
        value: 'allUsers',
    })

    // get data shipments
    useEffect(() => {
        loadData()
    }, [tabFilter])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setData(
                tabFilter.value === 'allUsers'
                    ? ManageUsersDataDummy
                    : ManageUsersDataDummy.filter(
                        (x) => x.userStatus === tabFilter.value,
                    ),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
    }
}

export default useManageUsers