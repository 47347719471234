/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from 'react'

import {
    APIProvider,
    Map,
    useMap,
    AdvancedMarker,
    Pin,
} from '@vis.gl/react-google-maps'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import type { Marker } from '@googlemaps/markerclusterer'
import './style.css'

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
const MAP_ID = process.env.REACT_APP_MAP_ID as string

type IPoint = {
    name: string;
    lat: number;
    lng: number;
    key: string;
    status: boolean;
}
type IMapProps = { points?: IPoint[] }


const GoogleMap = ({ points }: IMapProps) => (
    <APIProvider apiKey={API_KEY}>
        <Map
            mapId={MAP_ID}
            defaultCenter={{ lat: -20.789275, lng: 113.921327 }}
            defaultZoom={2}
            gestureHandling={'greedy'}
            disableDefaultUI
        >
            {points ? <Markers points={points} /> : ''}
        </Map>
    </APIProvider>
)

const Markers = ({ points }: IMapProps) => {
    const map = useMap()
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
    const clusterer = useRef<MarkerClusterer | null>(null)

    // Initialize MarkerClusterer
    useEffect(() => {
        if (!map) return
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map })
        }
    }, [map])

    // Update markers
    useEffect(() => {
        clusterer.current?.clearMarkers()
        clusterer.current?.addMarkers(Object.values(markers))
    }, [markers])

    const setMarkerRef = (marker: Marker | null, key: string) => {
        if (marker && markers[key]) return
        if (!marker && !markers[key]) return

        setMarkers((prev) => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                const newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    const getTypesLocation = async (
        myLat: number,
        myLng: number,
        myApiKey: string,
    ) => {
        return await fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            myLat +
            ',' +
            myLng +
            '&key=' +
            myApiKey,
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(
                    'ADDRESS GEOCODE is BACK!! => ' +
                    JSON.stringify(responseJson),
                )
            })
    }

    return (
        <>
            {points && points.map((point) => {
                return (
                    <AdvancedMarker
                        position={point}
                        key={point.key}
                        ref={(marker) => setMarkerRef(marker, point.key)}
                    >
                        <Pin
                            background={point.status ? "#0058FF" : "#8D99A5"}
                            borderColor={"#EEF3FA"}
                            glyphColor={"#EEF3FA"}
                        ></Pin>
                        <span className="tree"></span>
                    </AdvancedMarker>
                )

            }
            )}
        </>
    )
}

export default GoogleMap
