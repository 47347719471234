import TabMaster from 'components/tab-master/tab-master.component'
import {
    ContactListStatusHeaders,
    ContactListSummaryHeaders,
} from './contact-list.static'
import { IContactList } from './contact-list.interface'
import useContactList from './contact-list.service'
import ContactListModal from './components/contact-list-modal.component'
import SimpleTable from 'components/simple-table/simple-table.component'

const ContactList = () => {
    const {
        createModalService,
        editModalService,
        previewModalService,
        removeModalService,
        tabFilter,
        data,
        detailData,
        setTabFilter,
        setDetailData,
    } = useContactList()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={ContactListStatusHeaders}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Code (min 3 char)'}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton2={{
                        onClick: () => {},
                        icon: 'ri-filter-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            createModalService?.toggleModalHandling()
                        },
                        label: 'CREATE NEW CONTACT',
                        className: '!text-size-S',
                    }}
                />

                <SimpleTable<IContactList>
                    headers={ContactListSummaryHeaders}
                    data={data}
                    isAddRow={false}
                    addRowLabel="Add New Contact"
                    onRowClick={(data) => {
                        previewModalService.openModalHandling()
                        setDetailData(data)
                    }}
                    customBuildParam4={() => ({
                        // set details
                        func1: () => {
                            // setIsCreateAddress(false)
                            createModalService.openModalHandling()
                            setDetailData(detailData)
                        },
                        func2: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                />
            </div>
            {createModalService?.isModalOpen && (
                <ContactListModal
                    modalService={createModalService}
                    moduleTitle="Create Contact Details"
                    disable={false}
                    enableSave={true}
                    isCreateAddress={true}
                    data={detailData as IContactList}
                />
            )}
            {editModalService?.isModalOpen && (
                <ContactListModal
                    modalService={editModalService}
                    moduleTitle="Edit Contact Details"
                    disable={false}
                    enableSave={true}
                    isCreateAddress={true}
                    data={detailData as IContactList}
                />
            )}
            {previewModalService?.isModalOpen && (
                <ContactListModal
                    modalService={previewModalService}
                    moduleTitle="Preview Contact Details"
                    disable={true}
                    enableSave={true}
                    data={detailData as IContactList}
                    isCreateAddress={false}
                />
            )}
        </div>
    )
}

export default ContactList
