/* eslint-disable no-unused-vars */
import { ITable } from './table-expandable-detail.interface'
import SpinnerTable from 'components/spinner-table/spinner-table.component'
import Pagination from 'components/pagination/pagination.component'
import { useTable } from './table-expandable-detail.service'
import Button from 'components/button/button.component'
import EmptyResult from 'components/empty-result/empty-result.component'
import PoGenerateReportModal from './component/po-generate-report-modal.component'
import { TableExpandableRow } from './component/table-expandable-row.component'

function TableExpandableDetail<T>({ usePagination = true, ...props }: ITable<T>) {

    const loadingMessage = `loading ${props.moduleTitle} Data . . .`
    const notFoundMessage = `No data found`
    const {
        generatedData,
    } = useTable<T>({
        data: props.data,
        tabFilterItem: props.tabFilterItem,
    })

    return (
        <>
            <div className="h-full flex flex-col overflow-auto">
                <div className={`flex-1 ${props.containerClassname}`}>
                    {props.loading === true ? (
                        <SpinnerTable message={loadingMessage} />
                    ) : props.data.length <= 0 ? (
                        <EmptyResult message={notFoundMessage} />
                    ) : (
                        <div className='relative my-2 overflow-auto w-full h-[calc(100vh-260px)] rounded themes-bg-white'>
                            {generatedData.map((data: T, idx: number) => {
                                return (
                                    <div key={`id-row-${idx}`}>
                                        <TableExpandableRow
                                            data={data}
                                            summaryDetail={props.summaryDetail}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>

                {props?.modalService && (
                    <PoGenerateReportModal
                        modalService={props?.modalService}
                        resetFilter={props?.resetFilter}
                        components={props?.components}
                        moduleTitle={props?.moduleTitle}
                        data={props?.data}
                        headers={props?.headers}
                        exportType="xlxs"
                        getDataGenerate={props?.getDataGenerate}
                        generateReportData={props?.generateReportData}
                        GenerateReportHeaders={props?.GenerateReportHeaders}
                        removeFilter={props?.removeFilter}
                    />
                )}
            </div>

            {usePagination && (
                <div className="border-t themes-border-color flex justify-between">
                    <Pagination
                        meta={props.meta}
                        previousHandling={(page) => {
                            props?.previousHandling &&
                                props.previousHandling(page)
                        }}
                        nextHandling={(page) => {
                            props?.nextHandling && props.nextHandling(page)
                        }}
                    />
                    <div className="m-4 flex gap-2">
                        {props.additionalButtonBottom}
                        {props.enableExport ? (
                            <Button
                                label="Generate Report"
                                onClick={() => {
                                    props.modalService?.openModalHandling()
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default TableExpandableDetail
