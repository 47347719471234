import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import { OptionProps, components } from 'react-select'
import { IShippingMode } from '../new-quote.interface'

const OptionShippingMode = (
    props: OptionProps<IDropdownItem<IShippingMode>>,
) => {
    const { data, isSelected } = props

    return (
        <components.Option {...props}>
            <div className="">
                <div className="border-b">
                    <div>Carrier Rate</div>
                    <div
                        className={`${isSelected ? 'themes-text-brand-v4' : 'themes-text-gary-v4'}`}
                    >
                        {data?.additionalData?.rate}
                    </div>
                </div>
                <div className="border-b flex gap-5">
                    <div>
                        <div>Sailing Date</div>
                        <div>{data?.additionalData?.sailingDate}</div>
                    </div>
                    <div>
                        <div>Transit Time</div>
                        <div>{data?.additionalData?.transitTime}</div>
                    </div>
                    <div>
                        <div>Rates by Forwarder</div>
                        <div>{data?.additionalData?.forwarder}</div>
                    </div>
                </div>
            </div>
        </components.Option>
    )
}

export default OptionShippingMode
