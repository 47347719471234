import { ErrorMessage, useField } from 'formik'
import { IFormDatePicker } from './form-date-picker.interface'
import DatePicker from 'components/date-picker/date-picker.component'

function FormDatePicker({
    parentDivClassName = '',
    showErrorMessage = true,
    ...props
}: IFormDatePicker) {
    //formik
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helpers] = useField(props.name)

    // const borderColorClass =
    //     meta.error && meta.touched ? 'themes-border-red' : ''

    const isError = !(!meta.error || (meta.error && !meta.touched))
    const errorClassMessage = isError ? '' : 'hidden'

    return (
        <div className={`${parentDivClassName}`}>
            <DatePicker
                selectedDate={field.value}
                setSelectedDate={(value) => {
                    helpers.setValue(value)
                }}
                {...props}
            />
            <div
                className={`ml-small-x text-size-XS text-red-500 ${!showErrorMessage ? 'hidden' : errorClassMessage}`}
            >
                <ErrorMessage name={props.name} component="p" />
            </div>
        </div>
    )
}

export default FormDatePicker
