/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { IInput } from './input.interface'
import './input.style.css'
import { copyTextToClipboard, openEmailClient } from 'common/common.service'

const Input = ({
    disabled = false,
    readonly = false,
    required = false,
    type = 'text',
    ...props
}: IInput) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [focus, setFocus] = useState(false)
    const showLabelClass = !props.label || props.label === '' ? 'hidden' : ''
    const isRequired = required ? 'required' : ''
    const labelFocus = focus ? 'themes-text-brand-v5' : ''
    const isDisabled = disabled ? 'disabled' : ''
    const iconClassNames = ' ml-[-2.3rem]'
    const iconClassDisabled =
        disabled && !props?.value ? 'opacity-70' : 'cursor-pointer'

    const handleFocusEvent = () => {
        setFocus(true)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setFocus(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div
            className={` ${props?.parentInputClassName || ''} inputParent-style mt-auto`}
        >
            <label className={`${showLabelClass} text-size-XS`}>
                <div className={`${isRequired} ${labelFocus} h-[9px]`}>
                    {props.label}
                </div>
            </label>
            <div
                className={`${props.descriptionValue && 'input-group'} flex w-full items-center ${props.descriptionValue && disabled && 'themes-bg-gray-v1'}`}
                id={props?.id}
            >
                {props.descriptionValue && (
                    <div
                        className={`themes-text-gray-v4 text-size-S mx-2 flex item gap-2 `}
                    >
                        <div className={`themes-text-gray-v4 text-size-S`}>
                            IDR
                        </div>
                        <div className="border-r"></div>
                    </div>
                )}
                <input
                    {...props}
                    ref={inputRef}
                    disabled={disabled}
                    className={`${props.className} ${isDisabled} `}
                    placeholder={props.placeholder}
                    type={type}
                    value={disabled && !props.value ? '-' : props.value}
                    readOnly={readonly}
                    onChange={(e) => {
                        if (props.onChange) props.onChange(e)
                    }}
                    onFocus={handleFocusEvent}
                />
                {props?.icon && (
                    <i
                        className={`${props.icon} ${iconClassDisabled} ${iconClassNames}`}
                        onClick={() => {
                            if (
                                props.icon === 'ri-file-copy-line' &&
                                props?.id &&
                                props.value
                            ) {
                                // Salin nilai dari input ke clipboard
                                copyTextToClipboard(props?.id, iconClassNames)
                            }

                            if (props.icon === 'ri-mail-line') {
                                openEmailClient(props.value || '')
                            }
                        }}
                    ></i>
                )}
            </div>
        </div>
    )
}

export default Input
