import Tab from 'components/tab/tab.component'
import { FormikProvider } from 'formik'
import useTabMaster from './tab-master.service'
import Tooltip from 'components/tooltip/tooltip.component'
import FormInput from 'components/form-input/form-input.component'
import Button from 'components/button/button.component'
import React from 'react'
import { ITabMaster } from './tab-master.interface'

const TabMaster = ({
    useSearch,
    placeHolderSearch,
    items,
    tabFilter,
    onChange,
    onSearchSubmit,
    iconButton1,
    iconButton2,
    iconButton3,
    actionButton1,
    containerSearchClassName,
    initialSearch,
}: ITabMaster) => {
    const { formik } = useTabMaster({
        handleSearch: onSearchSubmit,
        initialSearch,
    })

    const isButtonExists = !!(iconButton1 || iconButton2 || iconButton3)
    const isRightSectionVisible = isButtonExists || useSearch
    const flexWrap = useSearch ? 'flex-wrap-reverse' : ''
    const widthSideFlex = useSearch ? 'sm:w-full' : ' '

    const filterChildHeader = items?.filter(
        (value) => tabFilter?.value === value?.value,
    )[0]

    return (
        <>
            <div
                className={`px-3 pt-3 gap-1 flex ${flexWrap} justify-between border-b border-solid themes-border-color`}
            >
                <Tab
                    containerClassName="flex-grow w-[400px] sm:w-full"
                    items={items ?? []}
                    tabFilter={tabFilter}
                    onChange={(item) => {
                        onChange(item)
                    }}
                />

                <>
                    {isRightSectionVisible && (
                        <div
                            className={`${widthSideFlex} ${containerSearchClassName || ''} flex flex-grow -mt-1 justify-end `}
                        >
                            {useSearch && (
                                <FormikProvider value={formik}>
                                    <form
                                        className="w-full flex justify-end"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            formik.handleSubmit()
                                        }}
                                    >
                                        <Tooltip
                                            text={
                                                'Search term must be at least 3 characters'
                                            }
                                            isShow={
                                                formik.errors.searchTerm
                                                    ? true
                                                    : false
                                            }
                                            containerClassName="w-full lg:max-w-[250px] "
                                        >
                                            <FormInput
                                                autoComplete="auto-off"
                                                hideError={true}
                                                placeholder={placeHolderSearch}
                                                parentDivClassName="!mb-0"
                                                icon="ri-search-line"
                                                name="searchTerm"
                                                onKeyDown={(
                                                    event: React.KeyboardEvent<HTMLInputElement>,
                                                ) => {
                                                    if (
                                                        event.key ===
                                                            'Backspace' &&
                                                        formik.values.searchTerm
                                                            .length === 1
                                                    ) {
                                                        formik.values.searchTerm =
                                                            ''
                                                        formik.handleSubmit()
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    </form>
                                </FormikProvider>
                            )}

                            {iconButton1 && (
                                <div>
                                    <Button
                                        onClick={() => {
                                            iconButton1.onClick()
                                        }}
                                        icon={iconButton1.icon}
                                        className={`${iconButton1.className} ml-2 w-btnIconWidth !border-transparent`}
                                        variant="default"
                                    />

                                    {iconButton1?.filterOverlayComponent
                                        ? iconButton1.filterOverlayComponent
                                        : ''}
                                </div>
                            )}

                            {iconButton2 && (
                                <div>
                                    <Button
                                        onClick={() => {
                                            iconButton2.onClick()
                                        }}
                                        icon={iconButton2.icon}
                                        className={`${iconButton2.className} ml-2 w-btnIconWidth !border-transparent`}
                                        variant="default"
                                    />

                                    {iconButton2?.filterOverlayComponent
                                        ? iconButton2.filterOverlayComponent
                                        : ''}
                                </div>
                            )}

                            {iconButton3 && (
                                <div>
                                    <Button
                                        onClick={() => {
                                            iconButton3.onClick()
                                        }}
                                        icon={iconButton3.icon}
                                        className={`${iconButton3.className} ml-2 w-btnIconWidth !border-transparent`}
                                        variant="default"
                                    />
                                    {iconButton3?.filterOverlayComponent
                                        ? iconButton3.filterOverlayComponent
                                        : ''}
                                </div>
                            )}

                            {actionButton1 && (
                                <div>
                                    <Button
                                        onClick={() => {
                                            actionButton1.onClick()
                                        }}
                                        label={actionButton1.label}
                                        icon={actionButton1.icon}
                                        iconSuffix={actionButton1.iconSuffix}
                                        className={`${actionButton1.className} ml-2 w-btnRegularWidth !border-transparent !text-size-S`}
                                        iconClassName={`${actionButton1.iconClassName}`}
                                        variant="brand"
                                    />
                                    {actionButton1?.OverlayComponent
                                        ? actionButton1.OverlayComponent
                                        : ''}
                                </div>
                            )}
                        </div>
                    )}
                </>
            </div>

            {/* child tabs */}
            {filterChildHeader?.childStatus?.length ? (
                <div
                    className={`px-3 pt-3 gap-1 flex ${flexWrap} justify-between border-b border-solid themes-border-color`}
                >
                    <Tab
                        items={filterChildHeader?.childStatus || []}
                        onChange={(item) => {
                            onChange({
                                ...filterChildHeader,
                                childStatus: [item],
                            })
                        }}
                        tabFilter={tabFilter?.childStatus[0]}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default TabMaster
