/* eslint-disable no-unused-vars */
import { ITabItem } from "components/tab/tab.interface";
import StatusCard from "components/status-card/status-card.component";
import { ISTColumn } from "components/simple-table/simple-table.interface";

export const contentShipDetailHeader: ITabItem[] = [
  {
    label: "Details",
    key: "status",
    value: "details",
    className: "gray"
  },
  {
    label: "List Items",
    key: "status",
    value: "listItems",
    className: 'gray'
  },
  // {
  //   label: "Milestones",
  //   key: "status",
  //   value: "milestones",
  //   textColorClassname: "text-logistical-gray-ver4",
  //   lineColorClassname: "bg-logistical-gray-ver4",
  // },
  {
    label: "Routing",
    key: "status",
    value: "routing",
    className: 'gray'
  },
  {
    label: "Goods/Packs",
    key: "status",
    value: "goodsPacks",
    className: 'gray'
  },
  {
    label: "Containers",
    key: "status",
    value: "containers",
    className: 'gray'
  },
  {
    label: "eDocumentation",
    key: "status",
    value: "eDocumentation",
    className: 'gray'
  },
  {
    label: "Related Invoice",
    key: "status",
    value: "relatedInvoice",
    className: 'gray'
  },
];

export const shippingDetailsHeader: any = {
  shippingDetails: [
    {
      accessor: "shipmentStatus",
      label: "Status",
      customBuild: (data: string) => (
        <div className="my-1">
          <StatusCard status={data} />{" "}
        </div>
      ),
    },
    {
      accessor: "houseBill",
      label: "HouseBill",
    },
    {
      accessor: "origin",
      label: "Origin",
    },
    {
      accessor: "etd",
      label: "ETD",
    },
    {
      accessor: "destination",
      label: "Destination",
    },
    {
      accessor: "eta",
      label: "ETA",
    },
    {
      accessor: "weight",
      label: "Weight",
    },
    {
      accessor: "volume",
      label: "Volume",
    },
    {
      accessor: "chargeable",
      label: "Chargeable",
    },
    {
      accessor: "wv",
      label: "WV",
    },
    {
      accessor: "packs",
      label: "Packs",
    },
    {
      accessor: "inners",
      label: "Inners",
    },
    {
      accessor: "goodsValue",
      label: "Goods Value",
    },
    {
      accessor: "insuranceValue",
      label: "Insurance Value",
    },
  ],
  mode: [
    {
      accessor: "transport",
      label: "Transport",
    },
    {
      accessor: "type",
      label: "Type",
    },
    {
      accessor: "container",
      label: "Container",
    },
  ],
};

export const headerPillsShippingDetails = [
  {
    label: "INFORMATION",
    accessor: "information",
  },
  {
    label: "DESCRIPTIONS & INSTRUCTIONS",
    accessor: "descriptionsAndIntructions",
  },
];

export const descriptionAndIntructionsHeader = [
  {
    accessor: "description",
    label: "Description",
  },
  {
    accessor: "marksAndNumbers",
    label: "Marks&Numbers",
    // customBuild: (data: any) =>
    //     data?.map((x: any) => (
    //         <div className="flex">
    //             <div className="max-w-[100px] min-w-[100px]">{x.date}</div>
    //             <div className="">{x.description}</div>
    //         </div>
    //     )),
  },
  {
    accessor: "incoterm",
    label: "Incoterm",
  },
  {
    accessor: "additionalTerms",
    label: "Additional Terms",
    // customBuild: (data: any) =>
    //     data?.map((x: any) => (
    //         <div className="flex">
    //             <div className="max-w-[100px] min-w-[100px]">{x.date}</div>
    //             <div className="">{x.description}</div>
    //         </div>
    //     )),
  },
  {
    accessor: "spotRate",
    label: "Spot Rate",
  },
  {
    accessor: "serviceLevel",
    label: "Service Level",
  },
  {
    accessor: "spotRate",
    label: "Spot Rate",
  },
  {
    accessor: "entryDetails",
    label: "Entry Details",
  },
  {
    accessor: "shippingDate",
    label: "Issue Date",
    customBuild: (data: any) => data?.issueDate,
  },
  {
    accessor: "shippingDate",
    label: "Expiry Date",
    customBuild: (data: any) => data?.expiryDate,
  },
  {
    accessor: "releaseType",
    label: "Release Type",
  },
  {
    accessor: "chargesApply",
    label: "Charges Apply",
  },
  {
    accessor: "screeningStatus",
    label: "Screening Status",
  },
];

export const pillSAddressesDetailsHeader = [
  {
    accessor: "shipper",
    label: "SHIPPER",
  },
  {
    accessor: "consignee",
    label: "CONSIGNEE",
  },
  {
    accessor: "localClient",
    label: "LOCAL CLIENT",
  },
];

export const addressesDetailsShipperHeader = [
  {
    accessor: "company",
    label: "Company",
  },
  {
    accessor: "address",
    label: "Address",
  },
  {
    accessor: "phone",
    label: "Phone",
  },
  {
    accessor: "email",
    label: "Email",
  },

  {
    accessor: "fax",
    label: "Fax",
  },
];

export const addressesDetailsConsigneeHeader = [
  {
    accessor: "companyA",
    label: "Company",
  },
  {
    accessor: "deliveryAddressA",
    label: "Delivery Address",
  },
  {
    accessor: "phoneA",
    label: "Phone",
  },
  {
    accessor: "emailA",
    label: "Email",
  },

  {
    accessor: "faxA",
    label: "Fax",
  },
];

export const localAddressesDetailsConsigneeHeader = [
  {
    accessor: "companyB",
    label: "Company",
  },
  {
    accessor: "deliveryAddressB",
    label: "Delivery Address",
  },
  {
    accessor: "phoneB",
    label: "Phone",
  },
  {
    accessor: "emailB",
    label: "Email",
  },

  {
    accessor: "faxB",
    label: "Fax",
  },
];

export const listItemsHeader = [
  {
    accessor: "itemNo",
    label: "Item No.",
  },
  {
    accessor: "itemDescription",
    label: "Item Description",
  },
  {
    accessor: "category",
    label: "Category",
  },
  {
    accessor: "unit",
    label: "Unit",
  },
  {
    accessor: "price",
    label: "Price",
  },
  {
    accessor: "amount",
    label: "Amount",
  },
  {
    accessor: "material",
    label: "Material",
  },
  {
    accessor: "hsCode",
    label: "HS Code",
  },
  {
    accessor: "size",
    label: "Size",
  },
  {
    accessor: "color",
    label: "Color",
  },
  {
    accessor: "handlingNote",
    label: "Handling Note",
  }
];

export const transportHeader = [
  {
    accessor: "legOrder",
    label: "Leg",
  },
  {
    accessor: "transportMode",
    label: "Mode",
  },
  {
    accessor: "forwardingConsole",
    label: "Parent",
  },
  {
    accessor: "waybillNumber",
    label: "Bill",
  },
  {
    accessor: "vesselName",
    label: "Vessel",
  },
  {
    accessor: "voyageFlightNo",
    label: "Voyage/Flight",
  },
  {
    accessor: "portOfLoading",
    label: "Load",
  },
  {
    accessor: "portOfDischarge",
    label: "Discharge",
  },
  {
    accessor: "etd",
    label: "ETD",
  },
  {
    accessor: "eta",
    label: "ETA",
  },
  {
    accessor: "ata",
    label: "ATA",
  },
  {
    accessor: "bookingStatus",
    label: "Status",
  },
  {
    accessor: "carrier",
    label: "Carrier",
  },
];

export const containerHeader: ISTColumn<any>[] = [
  {
    accessor: "containerNo",
    label: "Container No.",
  },
  {
    accessor: "shipmentNo",
    label: "Shipment No.",
  },
  {
    accessor: "sealNo",
    label: "Seal No.",
  },
  {
    accessor: "containerType",
    label: "Container Type",
  },
  {
    accessor: "containerMode",
    label: "Container Mode",
  },
  {
    accessor: "emptyReturnedOn",
    label: "Empty Returned On",
  },
  {
    accessor: "tareWeight",
    label: "Tare Weight",
  },
  {
    accessor: "goodWeight",
    label: "Weight",
  },
  {
    accessor: "deliveryMode",
    label: "Delivery Mode",
  },
  {
    accessor: "estDelivery",
    label: "Est. Delivery",
  },
  {
    accessor: "estReturn",
    label: "Est. Return",
  },
  {
    accessor: "actReturn",
    label: "Act. Return",
  },
];

export const milestonesHeader = [
  {
    accessor: "containerNo",
    label: "Parent Job",
  },
  {
    accessor: "containerNo",
    label: "Sequence",
  },
  {
    accessor: "containerNo",
    label: "Description",
  },
  {
    accessor: "containerNo",
    label: "Event Code",
  },
  {
    accessor: "containerNo",
    label: "Estimated Date (loc)",
  },
  {
    accessor: "containerNo",
    label: "Actual Start (loc)",
  },
  {
    accessor: "containerNo",
    label: "Condition Reference",
  },
  {
    accessor: "containerNo",
    label: "Condition Type",
  },
];

export const goodsPacksHeader = [
  {
    accessor: "containerNo",
    label: "Container No.",
  },
  {
    accessor: "pieces",
    label: "Pieces",
  },
  {
    accessor: "packType",
    label: "pack Type",
  },
  {
    accessor: "hsCode",
    label: "HS Code",
  },
  {
    accessor: "length",
    label: "Length",
  },
  {
    accessor: "width",
    label: "Width",
  },
  {
    accessor: "height",
    label: "Height",
  },
  {
    accessor: "ud",
    label: "UD",
  },
  {
    accessor: "weight",
    label: "Weight",
  },
  {
    accessor: "uw",
    label: "UW",
  },
  {
    accessor: "volume",
    label: "Volume",
  },
  {
    accessor: "uv",
    label: "UV",
  },
  {
    accessor: "descriptions",
    label: "Descriptions",
  },
  {
    accessor: "marksAndNumbers",
    label: "Marks and Numbers",
  },
];

export const eDocumentationHeader = [
  {
    accessor: "containerNo",
    label: "Date Added",
  },
  {
    accessor: "containerNo",
    label: "Document Type",
  },
  {
    accessor: "containerNo",
    label: "File Name",
  },
  {
    accessor: "containerNo",
    label: "Desc.",
  },
  {
    accessor: "containerNo",
    label: "Prog. Description",
  },
  {
    accessor: "containerNo",
    label: "System Generated",
  },
  {
    accessor: "containerNo",
    label: "Deleted",
  },
  {
    accessor: "action",
    label: "Action",
  },
];

export const relatedInvoiceHeader = [
  {
    accessor: "invoiceNo",
    label: "Invoice Number",
  },
  {
    accessor: "issuer",
    label: "Issuer",
  },
  {
    accessor: "type",
    label: "Type",
  },
  {
    accessor: "terms",
    label: "Terms",
  },
  {
    accessor: "invDate",
    label: "Inv Date",
  },
  {
    accessor: "dueDate",
    label: "Due Date",
  },
  {
    accessor: "currency",
    label: "Currency",
  },
  {
    accessor: "amount",
    label: "Amount",
  },
  {
    accessor: "outstandingAmount",
    label: "Outstanding Amount",
  },
  {
    accessor: "paidDate",
    label: "Paid Date",
  },
];

export const eDocsTypeOptions = [
  {
    label: 'Agents Instruction',
    value: 'agentsInstruction',
  },
  {
    label: 'Airway Bill/Ocean Bill of Lading',
    value: 'airwayBill',
  },
  {
    label: 'Arrival Notice',
    value: 'arrivalNotice',
  },
  {
    label: 'Arrival Notice and Charge Sheet',
    value: 'arrivalNoticeAndChargeSheet',
  },
  {
    label: 'Beneficiary Certificate',
    value: 'beneficiaryCertificate',
  },
  {
    label: 'Bill Of Entry',
    value: 'billOfEntry',
  },
  {
    label: 'Booking Cartage Advice',
    value: 'bookingCartageAdvice',
  },
  {
    label: 'Booking Confirmation',
    value: 'bookingConfirmation',
  },
  {
    label: 'Cartage Advice With Receipt',
    value: 'cartageAdviceWithReceipt',
  },
  {
    label: 'Certificate of Origin',
    value: 'certificateOfOrigin',
  },
  {
    label: 'Commercial Invoice',
    value: 'commercialInvoice',
  },
  {
    label: 'Container Release',
    value: 'containerRelease',
  },
  {
    label: 'Container Summary Sheet',
    value: 'containerSummarySheet',
  },
  {
    label: 'Cotton Certificate',
    value: 'cottonCertificate',
  },
  {
    label: 'Customs Clearance Documents',
    value: 'customsClearanceDocuments',
  },
  {
    label: 'Customs Status Advice',
    value: 'customsStatusAdvice',
  },
  {
    label: 'Delivery Labels',
    value: 'deliveryLabels',
  },
  {
    label: 'Documents Available Notice',
    value: 'documentsAvailableNotice',
  },
  {
    label: 'Draft Bill of Lading',
    value: 'draftBillOfLading',
  },
  {
    label: 'Entry Print/ Customs Declaration Documents',
    value: 'entryPrint',
  },
  {
    label: 'Export Cartage Advice',
    value: 'exportCartageAdvice',
  },
  {
    label: 'Export Cartage Advice with Receipt',
    value: 'exportCartageAdviceWithReceipt',
  },
  {
    label: 'Export Coload Master Manifest',
    value: 'exportColoadMasterManifest',
  },
  {
    label: 'Exporter Documents',
    value: 'exporterDocuments',
  },
  {
    label: 'Food Control Certificate',
    value: 'foodControlCertificate',
  },
  {
    label: 'Forwarders Cargo Receipt',
    value: 'forwardersCargoReceipt',
  },
  {
    label: 'Fumigation Certificate',
    value: 'fumigationCertificate',
  },
  {
    label: 'Gate Pass',
    value: 'gatePass',
  },
  {
    label: 'Health Clearance Certificate',
    value: 'healthClearanceCertificate',
  },
  {
    label: 'House Bill Of Lading - Signed',
    value: 'houseBillOfLading',
  },
  {
    label: 'House Waybill/ Bill of Lading',
    value: 'houseWaybill',
  },
  {
    label: 'Identification Check',
    value: 'identificationCheck',
  },
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Image - Damage',
    value: 'imageDamage',
  },
  {
    label: 'Import Cartage Advice',
    value: 'importCartageAdvice',
  },
  {
    label: 'Import Cartage Advice with Receipt',
    value: 'importCartageAdviceWithReceipt',
  },
  {
    label: 'Import Coload Master Manifest',
    value: 'importColoadMasterManifest',
  },
  {
    label: 'Insurance Certificate',
    value: 'insuranceCertificate',
  },
  {
    label: 'Internally Created Public Document',
    value: 'internallyCreatedPublicDocument',
  },
  {
    label: 'Letter of Credit',
    value: 'letterOfCredit',
  },
  {
    label: 'Load List',
    value: 'loadList',
  },
  {
    label: "Manufacturer's Declaration",
    value: 'manufacturersDeclaration',
  },
  {
    label: 'Master House',
    value: 'masterHouse',
  },
  {
    label: 'Miscellaneous CFS Document',
    value: 'miscellaneousCfsDocument',
  },
  {
    label: 'Miscellaneous Custom Document',
    value: 'miscellaneousCustomDocument',
  },
  {
    label: 'Miscellaneous Freight Document',
    value: 'miscellaneousFreightDocument',
  },
  {
    label: 'Miscellaneous Order Management Document',
    value: 'miscellaneousOrderManagementDocument',
  },
  {
    label: 'Miscellaneous Sales Manager Document',
    value: 'miscellaneousSalesManagerDocument',
  },
  {
    label: 'Miscellaneous Shipping Manager Document',
    value: 'miscellaneousShippingManagerDocument',
  },
  {
    label: 'Motor Vehicle Certificate',
    value: 'motorVehicleCertificate',
  },
  {
    label: 'Multiple Copies',
    value: 'multipleCopies',
  },
  {
    label: 'NAFTA Certificate',
    value: 'naftaCertificate',
  },
  {
    label: 'Original Bill Of Lading',
    value: 'originalBillOfLading',
  },
  {
    label: 'Outturn Report',
    value: 'outturnReport',
  },
  {
    label: 'Packing Declaration',
    value: 'packingDeclaration',
  },
  {
    label: 'Packing List',
    value: 'packingList',
  },
  {
    label: 'Permit',
    value: 'permit',
  },
  {
    label: 'Power of Attorney',
    value: 'powerOfAttorney',
  },
  {
    label: 'Power of Attorney Customs',
    value: 'powerOfAttorneyCustoms',
  },
  {
    label: 'Power of Attorney Forwarding',
    value: 'powerOfAttorneyForwarding',
  },
  {
    label: 'Profit Share Calculation Worksheet',
    value: 'profitShareCalculationWorksheet',
  },
  {
    label: 'Proof of Delivery',
    value: 'proofOfDelivery',
  },
  {
    label: 'Proof of Pickup',
    value: 'proofOfPickup',
  },
  {
    label: 'Quarantine Application/Coversheet',
    value: 'quarantineApplicationCoversheet',
  },
  {
    label: 'Quarantine Certificate',
    value: 'quarantineCertificate',
  },
  {
    label: 'Quarantine Packing Declaration',
    value: 'quarantinePackingDeclaration',
  },
  {
    label: 'Quarantine Print Preview',
    value: 'quarantinePrintPreview',
  },
  {
    label: 'Quarantine Remote Print',
    value: 'quarantineRemotePrint',
  },
  {
    label: 'Release/ Removal Authority',
    value: 'releaseRemovalAuthority',
  },
  {
    label: 'Scheduled Report',
    value: 'scheduledReport',
  },
  {
    label: 'Shippers Departure Notice',
    value: 'shippersDepartureNotice',
  },
  {
    label: 'Shippers Letter of Instruction',
    value: 'shippersLetterOfInstruction',
  },
  {
    label: 'Shipping Advice',
    value: 'shippingAdvice',
  },
  {
    label: 'Time Slot Confirmation',
    value: 'timeSlotConfirmation',
  },
  {
    label: 'Time Slot Request',
    value: 'timeSlotRequest',
  },
  {
    label: 'Transit Declaration of Commitment',
    value: 'transitDeclarationOfCommitment',
  },
  {
    label: 'Veterinary Certificate',
    value: 'veterinaryCertificate',
  },
  {
    label: 'Warehouse Bill of Lading',
    value: 'warehouseBillOfLading',
  },
  {
    label: 'Warehouse Cartage Advice',
    value: 'warehouseCartageAdvice',
  },
  {
    label: 'Warehouse Pick Order Summary',
    value: 'warehousePickOrderSummary',
  },
  {
    label: 'Withholding Certificate',
    value: 'withholdingCertificate',
  },
  {
    label: 'Others',
    value: 'others',
  },
]
