/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import './measurement-input.style.css'
import { IMeasurementInput, IOption } from './measurement-input.interface'

const MeasurementInput = ({
    options,
    measurement,
    setMeasurement,
    defaultOption,
    required = false,
    disabled = false,
    ...props
}: IMeasurementInput) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [focus, setFocus] = useState(false)
    const isRequired = required ? 'required' : ''
    const labelFocus = focus || dropdownOpen ? 'themes-text-brand-v5' : ''
    const borderFocus = focus || dropdownOpen ? 'themes-border-brand-v5' : ''
    const bgDisabled = disabled ? 'themes-bg-gray-v3' : ''
    const cursorDisabled = disabled ? '' : 'cursor-pointer'

    const handleFocusEvent = () => {
        setFocus(true)
    }

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = { ...measurement, qty: e.target.value }
        setMeasurement && setMeasurement(data)
    }

    const handleUnitChange = (selectedOption: IOption) => {
        const data = { ...measurement, unit: selectedOption.value }
        setMeasurement && setMeasurement(data)
        setDropdownOpen(false)
    }

    const toggleDropdownIcon = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation() // Prevents event bubbling
        setDropdownOpen(!dropdownOpen)
    }

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setDropdownOpen(false)
                setFocus(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        if (defaultOption) {
            const data = { ...measurement, unit: defaultOption.value }
            setMeasurement && setMeasurement(data)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className={`measurement-input-parent`} ref={dropdownRef}>
            <div className="w-full h-inherit">
                {props.label && (
                    <label>
                        <div className="h-[1px]">
                            <p className={`${isRequired} ${labelFocus}`}>
                                {props.label}
                            </p>
                        </div>
                    </label>
                )}
                <input
                    type="number"
                    value={measurement?.qty}
                    onChange={handleQtyChange}
                    placeholder={props?.placeholder || 'Enter here'}
                    className={`${borderFocus} ${bgDisabled}`}
                    onFocus={handleFocusEvent}
                    disabled={disabled}
                />
            </div>

            <div className={`unit ${borderFocus} ${bgDisabled}`}>
                <span className="border-l pl-2">{measurement?.unit}</span>
                <i
                    className={`${dropdownOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} ${cursorDisabled}`}
                    onClick={(data) => !disabled && toggleDropdownIcon(data)}
                ></i>
            </div>
            {dropdownOpen && (
                <div className={`dropdown-list`}>
                    {options.map((unitOption) => {
                        const activeClass =
                            unitOption.value === measurement?.unit
                                ? 'themes-bg-brand-v2'
                                : 'themes-bg-white'
                        return (
                            <div
                                key={unitOption.value}
                                onClick={() => handleUnitChange(unitOption)}
                                className={`${activeClass} p-2 cursor-pointer rounded themes-bg-hover-gray-v5:hover`}
                            >
                                {unitOption.label}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MeasurementInput
