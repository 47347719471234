import TabMaster from 'components/tab-master/tab-master.component'
import Table from 'components/table/table.component'
import useBookings from './bookings.service'
import { BookingStatusHeader, BookingSummaryHeader } from './bookings.static'
import { IBookings } from './bookings.interface'
import { BookingDataDummy, metaDummy } from './bookings.dummy'
import BookingFilterOverlay from './components/booking-filter-overlay.component'
import BookingFilter from './components/booking-filter.component'
import NewBookingOverlay from '../shipments/components/new-booking-overlay.component'

const Bookings = () => {
    const {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        setTabFilter,
    } = useBookings()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={BookingStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Shipment ID (min 3 char)'}
                    onSearchSubmit={() => { }}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <BookingFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () => { },
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            newBookingOverlayService.toggleOverlayHandling()
                        },
                        OverlayComponent: (
                            <NewBookingOverlay
                                overlayService={newBookingOverlayService}
                            />
                        ),
                        label: 'NEW BOOKING',
                        className: '!text-size-S',
                    }}
                />

                <Table<IBookings>
                    headers={BookingSummaryHeader}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={() => { }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    components={<BookingFilter isGenerateReport={true} />}
                    resetFilter={() => { }}
                    getDataGenerate={() => { }}
                    generateReportData={BookingDataDummy}
                    GenerateReportHeaders={BookingSummaryHeader}
                    removeFilter={() => { }}
                />
            </div>
        </div>
    )
}

export default Bookings
