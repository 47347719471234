import Header from '../components/header.component'
import { useState } from 'react'
import { news } from './row2.dummy'
import FilterSection from '../components/filter-section.component'

function TableOpenPO() {
    const [isFilter, setIsFilter] = useState<boolean>(false)
    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="row-span-1 content-center h-5"
                title="Industry News"
                filter={`Last 7 Days (17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />
            {isFilter ? (
                <FilterSection
                    className='grid gap-3 mt-6'
                    displayedInform={true}
                    filterBy={true}
                    dateRange={true}
                />
            ) : (
                <div className="row-span-10 overflow-y-auto mt-[10px] border-t border-gray-300 mb-[-20px]">
                    <table className="table-auto w-full">
                        <tbody className="text-gray-700">
                            {news.map((item: any, index: number) => {
                                return (
                                    <tr
                                        key={index}
                                        className="w-full border-b border-gray-200 items-center pt-1 pb-1 grid grid-cols-12"
                                    >
                                        <td className="line-clamp-3 col-span-11 text-blue-950 text-base font-normal font-['Basis Grotesque Arabic Pro']">
                                            {item}
                                        </td>
                                        <td className='col-span-1 pl-[10px] pr-2 border-l border-gray-300'>
                                            <i className="ri-arrow-right-s-line"></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    )
}

export default TableOpenPO
