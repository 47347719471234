import { useState } from 'react'
import Dropdown from 'components/dropdown/dropdown.component'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import '../style.css'
import Header from './header.component'
import { optionDates, optionDisplay } from '../dashboard.dummy'
import { DashboardItem } from '../row1/row1.dummy'

interface ProgressProps {
    data: DashboardItem
}

function Progress({ data }: ProgressProps) {
    const { title, total, summaryTotal, detail, isIncrease } = data

    const [isFilter, setIsFilter] = useState<boolean>(false)

    const [dateOption, setDateOption] = useState<
        IDropdownItem<unknown> | undefined
    >({
        value: '7D',
        label: 'Last 7 Days',
    })
    const [displayOption, setDisplayOption] = useState<
        IDropdownItem<unknown> | undefined
    >()

    const getSubtitle = (module: string) => {
        switch (module) {
            case 'Bookings (SEA)':
                return ['Booked', 'Confirmed', 'Cancelled']
            case 'Shipment Status':
                return ['Ahead of schedule', 'On schedule', 'Delayed']
            case 'Schedule Changes':
                return ['Rollover Vessel', 'Routing Change', 'Other Changes']
            case 'Warehouse':
                return ['In stock', 'Low stock', 'Out of stock']
            case 'C02e Emission':
                return ['Sea', 'Flight', 'Road']
            case 'SCFI Index':
                return [
                    'Disclaimer: The SCFI is a global market reference for twenty-foot sea containers, and does not represent specific trade-lane prices.',
                ]
        }
    }

    return (
        <div className="grid grid-rows-1 gap-0  h-[100%]">
            {/* Title */}
            <Header
                className="row-span-2"
                title={title}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                filter={`Last 7 Days`}

            />
            {isFilter ? (
                <div className={`grid 2xl:row-span-12 2xl:gap-[4px] md:row-span-6 md:gap-[4px] sm:row-span-6 sm:gap-[5px]`}>
                    <Dropdown
                        options={optionDates}
                        onClick={(value) => {
                            setDateOption(value)
                        }}
                        className='customDropdown'
                        dropDownIndicator={true}
                        useBorder={true}
                        value={dateOption}
                    />
                    <Dropdown
                        options={optionDisplay}
                        onClick={(value) => {
                            setDisplayOption(value)
                        }}
                        className='customDropdown'
                        placeholder='Displayed details'
                        dropDownIndicator={true}
                        useBorder={true}
                        value={displayOption}
                    />
                </div>
            ) : (
                <>
                    {/* Summary */}
                    <div className={`grid row-span-3 transition-opacity`}>
                        <div className="items-center gap-[12px] inline-flex">
                            <div className="text-gray-700 lg:text-[20px] md:text-[32px] font-bold font-['Bahnschrift']">
                                {total}
                            </div>
                            <div
                                className={`p-[5px] self-center ${isIncrease ? 'bg-green-100' : 'bg-red-100'} px-1.5 rounded flex-col justify-center items-center gap-2.5 inline-flex`}
                            >
                                <div className="justify-start items-center inline-flex">
                                    <div className="text-zinc-600 lg:text-[14px] md:text-[20px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]">
                                        {summaryTotal}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Detail Summary */}
                    <div className={`grid row-span-5 grid-cols-8 grid-rows-3 text-gray-500 lg:text-[12px] md:text-xs font-['Bahnschrift']`}>
                        {title !== 'SCFI Index' ? (
                            <>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[0]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail1}
                                </div>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[1]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail2}
                                </div>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[2]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail3}
                                </div>
                            </>
                        ) : (
                            <div className="col-span-8 row-span-3 line-clamp-3">
                                {getSubtitle(title)?.[0]}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default Progress
