/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import Button from 'components/button/button.component'
import Pills from 'components/pills/pills.component'
import { IQuoteSummaryPills } from './new-quote.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { quoteSummaryPillHeaders } from './new-quote.static'
import { FormikProvider } from 'formik'
import FormInput from 'components/form-input/form-input.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import useNewQuote from './new-quote.service'
import FormDatePicker from 'components/form-date-picker/form-date-picker.component'
import { portDummy } from './new-quote.dummy'
import ButtonPreviewDocument from 'components/button-preview-document/button-preview-document.component'
import PreviewQuote from './components/preview-quote.component'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'

const newQuote = ({ isDetailPage }: { isDetailPage: boolean }) => {
    const {
        formik,
        buttonAction,
        mallModal,
        navigate,
        setPillItems,
        getContentQuoteSummary,
    } = useNewQuote(isDetailPage)

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="p-3 flex justify-between items-center">
                    <div>
                        {isDetailPage ? 'QUOTE ID QRN000002' : 'GET A QUOTE'}
                    </div>
                    <div className="flex gap-3">
                        <ButtonPreviewDocument
                            onClick={() => {}}
                            icon="ri-file-pdf-2-line"
                            label="PREVIEW PDF"
                            componentPdf={<PreviewQuote />}
                            isPreview={true}
                        />
                        <div className="hidden">
                            <ButtonPreviewDocument
                                onClick={() => {}}
                                icon="ri-file-pdf-2-line"
                                label="PREVIEW PDF"
                                componentPdf={<PreviewQuote />}
                                isPreview={false}
                                id="quote-preview-document"
                            />
                        </div>
                        {buttonAction}
                    </div>
                </div>
                <div className="border-b"></div>
                <FormikProvider value={formik}>
                    <div className="h-[calc(100vh-14.8rem)] grid grid-cols-7 gap-3  p-3">
                        <div className="col-span-2 flex flex-col gap-3 border-r pr-3 overflow-auto">
                            <div>Quote Details</div>
                            <div className="grid grid-cols-8 gap-3">
                                <FormDatePicker
                                    name={'startDate'}
                                    label="Start Date"
                                    isRange={false}
                                    parentDivClassName="col-span-4 "
                                    disable={isDetailPage}
                                />
                                <FormDropdown
                                    label="Document Validity Period"
                                    options={[
                                        {
                                            label: 'A Week',
                                            value: 'A Week',
                                        },
                                    ]}
                                    dropDownIndicator={true}
                                    isClearable={true}
                                    parentDivClassName="col-span-4 !mb-0"
                                    name="documentValidityPeriod"
                                    disabled={isDetailPage}
                                />
                                <FormInput
                                    name={'customerName'}
                                    label="Customer Name"
                                    parentDivClassName="col-span-8 !mb-0"
                                    placeholder="Input customer name"
                                    disabled={isDetailPage}
                                />
                                <div className="col-span-8">
                                    Shipping Details
                                </div>
                                <FormDropdown
                                    disabled={isDetailPage}
                                    label="Transport Mode"
                                    options={[
                                        { label: 'AIR', value: 'air' },
                                        { label: 'SEA', value: 'sea' },
                                    ]}
                                    dropDownIndicator={true}
                                    isClearable={true}
                                    placeholder="Select Transport Mode"
                                    parentDivClassName="col-span-8 !mb-0"
                                    name="transportMode"
                                />
                                <FormDropdown
                                    label="Container Mode"
                                    options={[
                                        { label: 'LCL', value: 'lcl' },
                                        { label: 'Truck', value: 'truck' },
                                    ]}
                                    dropDownIndicator={true}
                                    isClearable={true}
                                    placeholder="Select Container Mode"
                                    parentDivClassName="col-span-8 !mb-0"
                                    name="containerMode"
                                    disabled={isDetailPage}
                                />
                                <FormDatePicker
                                    name={'etd'}
                                    disable={isDetailPage}
                                    label="ETD"
                                    isRange={false}
                                    parentDivClassName="col-span-8"
                                />
                                <FormDropdown
                                    label="Origin Port"
                                    options={[
                                        {
                                            label: 'IDSBY1',
                                            value: 'IDSBY',
                                        },
                                    ]}
                                    dropDownIndicator={true}
                                    isClearable={true}
                                    name="codeOriginPort"
                                    parentDivClassName="!mb-0 col-span-3"
                                    additionalOnClick={(data) => {
                                        const port = portDummy.find(
                                            (item) =>
                                                item.code ===
                                                (data as IDropdownItem)?.value,
                                        )
                                        formik.setValues((state) => ({
                                            ...state,
                                            originPort: port?.port || '',
                                        }))
                                    }}
                                    disabled={isDetailPage}
                                />
                                <FormInput
                                    name="originPort"
                                    disabled={true}
                                    parentDivClassName="col-span-5 !mb-0"
                                    parentInputClassName="w-full"
                                />
                                <FormDropdown
                                    label="Destination Port"
                                    options={[
                                        {
                                            label: 'AUBRN',
                                            value: 'AUBRN',
                                        },
                                    ]}
                                    dropDownIndicator={true}
                                    isClearable={true}
                                    placeholder="Select Destination Port"
                                    name="codeDestinationPort"
                                    parentDivClassName="!mb-0 col-span-3"
                                    additionalOnClick={(data) => {
                                        const port = portDummy.find(
                                            (item) =>
                                                item.code ===
                                                (data as IDropdownItem)?.value,
                                        )
                                        formik.setValues((state) => ({
                                            ...state,
                                            destinationPort: port?.port || '',
                                        }))
                                    }}
                                    disabled={isDetailPage}
                                />
                                <FormInput
                                    name="destinationPort"
                                    disabled={true}
                                    parentDivClassName="col-span-5 !mb-0"
                                    parentInputClassName="w-full"
                                />
                            </div>
                        </div>
                        <div className="col-span-5 flex flex-col gap-3 p-3 themes-bg-gray-v1 rounded overflow-auto">
                            <div>Quote Summary</div>
                            <div>
                                <Pills
                                    items={quoteSummaryPillHeaders}
                                    onChange={(
                                        e: ISTColumn<IQuoteSummaryPills>,
                                    ) => {
                                        setPillItems(e)
                                    }}
                                    variantUnActive="white"
                                />
                            </div>
                            {getContentQuoteSummary()}
                        </div>
                    </div>
                </FormikProvider>
                <div className="border-b"></div>
                <div className="p-3">
                    <Button onClick={() => navigate(-1)} label="BACK" />
                </div>
            </div>
            {mallModal}
        </div>
    )
}

export default newQuote
