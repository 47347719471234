import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { IQuoteSummaryPills } from './new-quote.interface'

export const quoteSummaryPillHeaders: ISTColumn<IQuoteSummaryPills>[] = [
    {
        label: 'CHARGE LIST',
        accessor: 'chargeList',
    },
    {
        label: 'NOTES',
        accessor: 'notes',
    },
]
