import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'

const QuotationFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Input label="SHIPMENT ID" placeholder="Enter Shipment ID" />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'IDJKT - Jakarta, Java',
                            value: 'IDJKT - Jakarta, Java',
                        },
                        {
                            label: 'THBKK - Bangkok',
                            value: 'THBKK - Bangkok',
                        },
                        {
                            label: 'USLAX - Los Angeles',
                            value: 'USLAX - Los Angeles',
                        },
                        {
                            label: 'USNYC - New York',
                            value: 'USNYC - New York',
                        },
                    ]}
                    label="ORIGIN PORT"
                    onClick={() => {}}
                    placeholder="Select Origin Port"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'AUMEL - Melbourne',
                            value: 'AUMEL - Melbourne',
                        },
                    ]}
                    label="DESTINATION PORT"
                    onClick={() => {}}
                    placeholder="Select Destination Port"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'UTC',
                            value: 'utc',
                        },
                    ]}
                    label="SYSTEM CREATE TIME UTC"
                    onClick={() => {}}
                    placeholder="Select System Create Time UTC"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'OneByOne Logistical PTY LTD',
                            value: 'OneByOne Logistical PTY LTD',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder="Add Filter"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
        </div>
    )
}

export default QuotationFilter
