/* eslint-disable no-unused-vars */
import { useState } from 'react'
import './toggle-switch.style.css'

const ToggleSwitch = ({
    initialOn,
    disabled = false,
    label,
    onClick,
}: {
    initialOn: boolean
    disabled?: boolean
    label?: string
    onClick?: () => void
}) => {
    const [isOn, setIsOn] = useState(initialOn)
    const disabledClass = disabled ? 'opacity-70' : ''

    return (
        <div className={`flex justify-between w-full ${disabledClass}`}>
            {label && <div>{label}</div>}
            <label className="switch">
                <input
                    type="checkbox"
                    checked={initialOn}
                    onClick={() => {
                        setIsOn(!isOn)
                        onClick && onClick()
                    }}
                    disabled={disabled}
                />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default ToggleSwitch
