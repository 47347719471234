import { IMeta } from 'common/common.interface'
import { useModal } from 'components/modal/modal.service'
import useOverlay from 'components/overlay/overlay.service'
import { ITabItem } from 'components/tab/tab.interface'
import { useNavigate } from 'react-router-dom'
import { metaDummy, SetupSiteDataDummy } from './setup-site.dummy'
import { useState } from 'react'

const useSetupSite = () => {
    const navigate = useNavigate()

    //state
    const [data, setData] = useState(SetupSiteDataDummy)
    const [meta, setMeta] = useState(metaDummy)
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Sites',
        totalData: SetupSiteDataDummy.length,
        key: 'all',
        value: 'all',
        className: 'brand',
    })

    //modal
    const generateReportModalService = useModal()

    //overlay
    const filterOverlayService = useOverlay()

    const setStatus = (item: ITabItem) => {
        setTabFilter(item)
        let newData
        let newMeta: IMeta
        newData = SetupSiteDataDummy
        newMeta = {
            current_page: 1,
            total_Items: newData.length,
            index_end: newData.length,
            index_start: 1,
            from: 1,
            to: 20,
            last_page: 1,
            per_page: 20,
            total_page: 1,
        }

        setData(newData ?? [])
        setMeta(newMeta)
    }

    return {
        generateReportModalService,
        filterOverlayService,
        data,
        meta,
        tabFilter,
        setData,
        navigate,
        setTabFilter,
        setStatus,
        setMeta,
    }
}

export default useSetupSite
