import { ITabItem } from 'components/tab/tab.interface'
import { ITableColumn } from 'components/table/table.interface'
import { OrganisationDataDummy } from './organisation.dummy'
import { Toast } from 'components/toast/toast.component'

export const OrganisationStatusHeaders: ITabItem[] = [
    {
        label: 'Active',
        totalData: OrganisationDataDummy.filter((x) => x.status === 'active')
            .length,
        key: 'status',
        value: 'active',
        className: 'brand',
    },
    {
        label: 'Non Active',
        totalData: OrganisationDataDummy.filter((x) => x.status === 'nonActive')
            .length,
        key: 'status',
        value: 'nonActive',
        className: 'red',
    },
]

export const OrganisationSummaryHeaders: ITableColumn[] = [
    {
        label: 'Code',
        accessor: 'code',
    },
    {
        label: 'Full Name',
        accessor: 'fullName',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'City',
        accessor: 'city',
    },
    {
        label: 'Country',
        accessor: 'country',
    },
    {
        label: 'Email',
        accessor: 'email',
    },
    {
        label: 'Number',
        accessor: 'number',
    },
    {
        label: 'Contact Type',
        accessor: 'contactType',
        customBuild: (data) => {
            console.log(data)
            return (
                <div className="flex gap-x-2">
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        SHIPPER
                    </div>
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        RECEIVABLES
                    </div>
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        +3
                    </div>
                </div>
            )
        },
    },
]

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })
