/* eslint-disable no-unused-vars */
import {
    ICreateOrgValidation,
    createOrgInitialValue,
    createOrgValidation,
} from 'form-validation/limajari/create-organisation.validation'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FormOrganisation from './contents/form-organisation.component'
import FormAddress from './contents/form-address.component'
import FormContact from './contents/form-contact.component'
import {
    IAddressList,
    IContactList,
} from '../organisation-details/organisation-details.interface'
import CreateOrEditAddressModal from '../organisation-details/components/create-or-edit-address-modal.component'
import {
    addressListInitialValue,
    addressListValidation,
    contactListInitialValue,
    contactListValidation,
} from 'form-validation/limajari/organisation-details.validation'
import { Toast } from 'components/toast/toast.component'
import { useModal } from 'components/modal/modal.service'
import { allContactDummy } from '../organisation.dummy'
import CreateOrEditContactModal from '../organisation-details/components/create-or-edit-contact-modal.component'
import AddContactModal from '../organisation-details/components/add-contact-modal.component'
import { ISteps } from 'components/progression-step/progression-step.interface'
import { stepCreateOrg } from '../organisation-details/organisation-details.static'

const useCreateOrganisation = () => {
    const navigate = useNavigate()

    // state
    const [stepFilter, setStepFilter] = useState<ISteps>({
        textIcon: '01',
        label: 'Details',
        value: 'org',
    })

    const [isCreateAddress, setIsCreateAddress] = useState<boolean>(true)
    const [countId, setCountId] = useState<number>(50)
    const [addressData, setAddressData] = useState<IAddressList[]>([])
    const [addressDetails, setAddressDetails] = useState<IAddressList>()
    const [contactListData, setContactListData] = useState<IContactList[]>([])
    const [allContact, setAllContact] =
        useState<IContactList[]>(allContactDummy)
    const [selectContact, setSelectContact] = useState<IContactList[]>()
    const [contactListDetails, setContactListDetails] = useState<IContactList>()

    // modal services
    const createOrEditAddressModal = useModal()
    const removeAddressModal = useModal()
    const createOrEditContactModal = useModal()
    const removeContactModalService = useModal()
    const addContactModalService = useModal()

    // formik
    const formik = useFormik<ICreateOrgValidation>({
        validationSchema: createOrgValidation,
        initialValues: createOrgInitialValue,
        onSubmit: (values) => {
            console.log('submit create', values)
            submitOrganisation()
        },
    })
    const addressListFormik = useFormik<IAddressList>({
        validationSchema: addressListValidation,
        initialValues: addressListInitialValue,
        onSubmit: (values) => {
            submitAddressList(values)
        },
    })
    const contactListFormik = useFormik<IContactList>({
        validationSchema: contactListValidation,
        initialValues: contactListInitialValue,
        onSubmit: (values) => {
            submitContactList(values)
        },
    })

    // useEffect
    useEffect(() => {
        if (addressData?.length && !addressDetails)
            setAddressDetails(addressData[0])
        if (addressData?.length)
            formik.setValues((values) => ({
                ...values,
                address: addressData,
            }))
        if (addressDetails) loadAddressListDetails()
    }, [addressData])

    useEffect(() => {
        if (contactListData?.length && !contactListDetails)
            setContactListDetails(contactListData[0])
        if (contactListData?.length)
            formik.setValues((values) => ({
                ...values,
                contact: contactListData,
            }))
    }, [contactListData])

    useEffect(() => {
        searchAllContact()
    }, [])

    // functions
    const getContent = () => {
        const contens: Record<string, JSX.Element> = {
            org: FormOrganisation(),
            address: FormAddress({
                data: addressData,
                detailsData: addressDetails,
                setDetails: (data) => setAddressDetails(data),
                setIsCreateAddress: (data) => setIsCreateAddress(data),
                createOrEditModal: createOrEditAddressModal,
                removeAddressModal: removeAddressModal,
                func1: setToggleAddress,
                removeAddress: removeAddress,
                formikValues: formik?.values?.address,
            }),
            contact: FormContact({
                data: contactListData,
                detailsData: contactListDetails,
                setDetailsData: (data) => setContactListDetails(data),
                createOrEditContactModal: createOrEditContactModal,
                removeModalService: removeContactModalService,
                removeData: removeContactList,
                setIsCreateAddress: (data: boolean) => setIsCreateAddress(data),
                addContactModalService: addContactModalService,
                formikValues: formik?.values?.contact,
            }),
        }
        return contens[stepFilter.value] || <></>
    }
    const submitOrganisation = () => {
        const name = formik?.values?.org?.fullName
        Toast({
            header: 'New Organisation Created',
            message: `New organisation:${name} successfully created.`,
            type: 'success',
        })
    }
    const nextCompo = () => {
        const x = stepCreateOrg.findIndex(
            (item) => item.value === stepFilter.value,
        )
        setStepFilter(stepCreateOrg[x + 1])
    }

    const previousCompo = () => {
        const x = stepCreateOrg.findIndex(
            (item) => item.value === stepFilter.value,
        )
        setStepFilter(stepCreateOrg[x - 1])
    }

    // segment address list
    const loadAddressListDetails = () => {
        const updateAddressData = addressData.find(
            (item) => item.id === addressDetails?.id,
        )
        if (updateAddressData) setAddressDetails(updateAddressData)
    }
    const submitAddressList = (data: IAddressList) => {
        if (isCreateAddress) {
            setAddressData([...addressData, { ...data, id: countId }])
            setCountId(countId + 1)
            Toast({
                header: 'New Address Created',
                message: `New Address:${data.addressName} successfully created.`,
                type: 'success',
            })
        } else {
            setAddressData(
                addressData.map((item) => (item.id === data.id ? data : item)),
            )
            Toast({
                header: 'Contact Updated',
                message: `Address:${data.addressName} successfully updated.`,
                type: 'success',
            })
        }
    }
    const setToggleAddress = (id: number, mainAddress: boolean) => {
        const updateData = addressData.map((item) => {
            if (item.id === id) {
                // Buat salinan item dan ubah mainAddress menjadi true
                return {
                    ...item,
                    ...(mainAddress && {
                        mainAddress: !item.mainAddress,
                    }),
                }
            }
            return item
        })
        setAddressData(updateData)
    }
    const removeAddress = () => {
        const updateData = addressData.filter(
            (address) => address.id !== addressDetails?.id,
        )
        setAddressData(updateData)
        setAddressDetails(updateData[0])
    }

    // segment contact list
    const submitContactList = (data: IContactList) => {
        if (isCreateAddress) {
            setContactListData([...contactListData, data])
            setAllContact([...allContact, data])
            selectContact && setSelectContact([...selectContact, data])
            Toast({
                header: 'New Contact Created',
                message: `New Contact:${data.contactName} successfully created.`,
                type: 'success',
            })
        } else {
            setContactListData(
                contactListData.map((item) =>
                    item.id === data.id ? data : item,
                ),
            )
            Toast({
                header: 'Contact Updated',
                message: `Contact:${data.contactName} successfully updated.`,
                type: 'success',
            })
        }
    }
    const removeContactList = () => {
        const updateData = contactListData.filter(
            (item) => item.id !== contactListDetails?.id,
        )
        setContactListData(updateData)
        setContactListDetails(updateData[0])
    }
    const loadAllContact = () => {
        const uniqueContacts = allContactDummy.filter(
            (allContact) =>
                !contactListData.some(
                    (contact) => contact.id === allContact.id,
                ),
        )
        setAllContact(uniqueContacts)
    }
    const searchAllContact = (contactName?: string) => {
        if (contactName) {
            const searchData = allContact.filter((contact) =>
                contact.contactName
                    .toLowerCase()
                    .includes(contactName.toLowerCase()),
            )
            searchData && setAllContact(searchData)
        } else {
            loadAllContact()
        }
    }
    const addContact = () => {
        if (selectContact?.length) {
            // Filter out contacts that are already in contactListData
            const newContacts = selectContact.filter(
                (contact) =>
                    !contactListData.some(
                        (existingContact) => existingContact.id === contact.id,
                    ),
            )

            if (newContacts.length) {
                setContactListData([...contactListData, ...newContacts])
                Toast({
                    header: 'Contact Updated',
                    message: `${newContacts.length} contacts have been successfully updated`,
                    type: 'success',
                })
            } else {
                Toast({
                    header: 'No New Contacts',
                    message:
                        'All selected contacts are already in the contact list.',
                    type: 'info',
                })
            }
        }
    }
    const selectContactfunc = (data: IContactList[]) => {
        if (selectContact?.length) setSelectContact([...selectContact, ...data])
        setSelectContact(data)
    }

    // components
    const allModal = (
        <>
            <CreateOrEditAddressModal
                modalService={createOrEditAddressModal}
                formik={addressListFormik}
                isCreate={isCreateAddress}
            />
            <CreateOrEditContactModal
                modalService={createOrEditContactModal}
                formik={contactListFormik}
                isCreate={isCreateAddress}
            />
            <AddContactModal
                modalService={addContactModalService}
                loadData={searchAllContact}
                data={allContact}
                setSelectContact={selectContactfunc}
                addContact={addContact}
            />
        </>
    )

    return {
        formik,
        allModal,
        stepFilter,
        setStepFilter,
        navigate,
        getContent,
        nextCompo,
        previousCompo,
        submitOrganisation,
    }
}

export default useCreateOrganisation
