import { IDropdownItem } from "components/dropdown/dropdown.interface";


export const typographySize: IDropdownItem[] = [
    {
        label: 'Small',
        value: 'small',
    },
    {
        label: 'Medium',
        value: 'medium',
    },
    {
        label: 'Large',
        value: 'large',
    },
]

export const colorPallet: IDropdownItem[] = [
    {
        label: 'Blue Family',
        value: 'blueFamily',
    },
]