/* eslint-disable no-unused-vars */
import Tab from 'components/tab/tab.component'
import { organisationDetailTabHeaders } from './organisation-details.static'
import useOrganisationDetail from './organisation-details.service'
import StatusCard from 'components/status-card/status-card.component'
import Button from 'components/button/button.component'
import { IButton } from 'components/button/button.interface'

const OrganisationDetail = () => {
    const {
        tabFilter,
        formik,
        isEdit,
        orgDetails,
        allModal,
        setIsEdit,
        setTabFilter,
        getContent,
    } = useOrganisationDetail()
    const borderComponent = <div className="border-b"></div>
    let properyButton1: IButton
    let properyButton2: IButton
    if (isEdit) {
        properyButton1 = {
            label: 'CANCEL EDIT',
            onClick: () => setIsEdit(false),
        }
        properyButton2 = {
            icon: 'ri-save-line',
            label: 'Save Changes',
            onClick: () => formik.submitForm(),
            variant: 'brand-inverse',
        }
    } else {
        properyButton1 = {
            label: 'EDIT ORG/CLIENT',
            icon: 'ri-edit-2-line',
            onClick: () => {
                setIsEdit(true)
                setTabFilter((data) => ({ ...data, value: 'details' }))
            },
        }
        properyButton2 = {
            icon: 'ri-more-2-line',
            className: 'border-none',
            onClick: () => {},
        }
    }
    const status = orgDetails.status === 'active' ? 'Active' : 'Non Active'

    return (
        <div className="flex flex-col container-global content-full-height overflow-hidden">
            <div className="text-size-L font-bold p-3 flex justify-between">
                <div className="flex">
                    <div className="mr-3">NIPSHETYO DETAILS</div>
                    <StatusCard status={status} />
                </div>
                <div className="flex gap-3">
                    <Button
                        onClick={properyButton1.onClick}
                        label={properyButton1.label}
                        icon={properyButton1.icon}
                    />
                    <Button
                        onClick={properyButton2.onClick}
                        icon={properyButton2.icon}
                        className={properyButton2.className}
                        label={properyButton2.label}
                        variant={properyButton2.variant}
                    />
                </div>
            </div>
            {borderComponent}
            <div>
                <Tab
                    containerClassName="px-3 pt-3 overflow-auto"
                    items={organisationDetailTabHeaders}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                />
            </div>
            {borderComponent}

            <div className="p-3">{getContent()}</div>

            {allModal}
        </div>
    )
}

export default OrganisationDetail
