import { useState } from 'react'

export const useContactListForm = (initialValues: any) => {
    const [values, setValues] = useState(initialValues)
    const [contactListData, setContactListData] = useState(initialValues)

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        // Handle form submission with the values
        console.log(values)
        resetForm() // Reset form after submission
    }

    const resetForm = () => {
        setValues(initialValues)
    }

    return {
        values,
        contactListData,
        handleChange,
        resetForm,
        handleSubmit,
        setContactListData,
    }
}
