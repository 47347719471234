import SimpleTable from 'components/simple-table/simple-table.component'
import { goodsPacksHeader } from '../shipments-details.static'

const GoodsPacks = () => {
    const data = [
        {
            containerNo: 'TRHU7898871',
            pieces: 10,
            packType: 'Box',
            hsCode: '324',
            length: 50,
            width: 30,
            height: 25,
            ud: 'cm',
            weight: '15 kg',
            uw: 'kg',
            volume: '3750 cm³',
            uv: 'cm³',
            descriptions: 'Fragile items',
            marksAndNumbers: 'ABC123',
        },
        {
            containerNo: 'TRHU7898872',
            pieces: 5,
            packType: 'Pallet',
            hsCode: '325',
            length: 120,
            width: 80,
            height: 150,
            ud: 'cm',
            weight: '100 kg',
            uw: 'kg',
            volume: '144000 cm³',
            uv: 'cm³',
            descriptions: 'Heavy machinery parts',
            marksAndNumbers: 'XYZ789',
        },
        {
            containerNo: 'TRHU7898873',
            pieces: 2,
            packType: 'Crate',
            hsCode: '326',
            length: 80,
            width: 40,
            height: 60,
            ud: 'cm',
            weight: '30 kg',
            uw: 'kg',
            volume: '192000 cm³',
            uv: 'cm³',
            descriptions: 'Electronic equipment',
            marksAndNumbers: 'LMN456',
        },
    ]

    return (
        <SimpleTable<any>
            headers={goodsPacksHeader}
            data={data}
            title={<>Goods/Packs</>}
        />
    )
}

export default GoodsPacks
