import AvatarCircle from 'components/avatar-circle/avatar-circle.component'
import Button from 'components/button/button.component'
import Table from 'components/table/table.component'
import './manage-user-details.style.css'
import useManageUserDetails from './manage-user-details.service'
import { IUserActivityLogs } from '../manage-users.interface'
import { userActivityHeaders } from '../manage-users.static'

const ManageUserDetails = () => {
    const { id, data, navigate } = useManageUserDetails()

    return (
        <div
            className={`manage-user-detail transition-opacity duration-500 !overflow-visible`}
        >
            <div className="content-1 content-full-height">
                {/* Flex 1, 2 rows */}

                <div className="content-1-A container-global">
                    {/* Image Session */}
                    <div className="flex justify-between items-centers gap-4">
                        <AvatarCircle name={'Darlene Osinski'} size={50} />
                        <div className="flex-1 flex flex-col leading-none ">
                            <div className="text-size-L pb-1 pt-1 font-bold">
                                {'Darlene Osinski'}
                            </div>
                            <div className="text-size-M">Admin Staff</div>
                        </div>

                        {/* Suspend option icon */}
                        {/* <SettingIcon
                            isActive={true}
                            suspendFunction={() => {
                                modalConfirmationService.openModalHandling()
                            }}
                        /> */}
                    </div>

                    {/* Border */}
                    <div className="border-t mx-[-1rem] my-4"></div>

                    {/* Detail User Session */}
                    <div className="flex-1 flex">
                        <div className="flex-1">
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between">
                                    <ValueLabel
                                        label={'Email Address'}
                                        value={'darlene.osinski@gmail.com'}
                                    />
                                    {/* <div
                                        className="flex gap-2 items-center text-logistical-gray-ver3 hover:text-logistical-gray-ver8 cursor-pointer"
                                        onClick={() => {
                                            openEmailClient()
                                        }}
                                    >
                                        Contact User
                                        <i className="ri-mail-line"></i>
                                    </div> */}
                                </div>
                                <ValueLabel
                                    label={'Phone Number'}
                                    value={'+612345678910'}
                                />
                                <ValueLabel
                                    label={'Job Title'}
                                    value={'Admin Staff'}
                                />
                                <ValueLabel
                                    label={'Job Category'}
                                    value={'Logistics'}
                                />
                                <ValueLabel
                                    label={'Organisation Name'}
                                    value={'International Freightbridge'}
                                />
                                <ValueLabel label={'Status'} value={'Active'} />
                            </div>
                        </div>
                    </div>

                    {/* Border */}
                    <div className="border-t mx-[-1rem] my-4"></div>
                    <div className="flex justify-between">
                        <Button
                            label="Back"
                            className="w-btnSmallWidth"
                            onClick={() => {
                                navigate('/manage-users')
                            }}
                        />
                        <Button
                            className="!w-[160px]"
                            icon="ri-edit-2-line"
                            label={'Update User'}
                            onClick={() => {
                                navigate('/manage-users/update/' + id)
                            }}
                            useUpperCase={true}
                        />
                    </div>
                </div>
            </div>
            <div className="content-2  container-global content-full-height !p-0">
                {/* Flex 2, 1 rows */}

                <div className="flex gap-4 pt-4 pl-4">
                    <div className="flex-grow text-size-L pb-1 pt-1 font-bold">
                        User Activity Logs
                    </div>
                </div>

                {/* Border */}
                <div className="border-t mx-[-1rem] mt-4"></div>

                <div className="flex-grow h-[calc(100vh-12.4rem)] items-center">
                    <Table<IUserActivityLogs>
                        headers={userActivityHeaders}
                        data={data[1].userActivity}
                        moduleTitle={'User'}
                        usePagination={false}
                        enableExport={false}
                        loading={false}
                    />
                </div>
            </div>

            {/* Modal for suspend and unsuspend user */}
            {/* <SelectModuleModal
                modalService={modalService}
                moduleList={moduleAdjustmentList}
                setModalSelectedPhase1={(selected) => {
                    setModalSelectedPhase1(selected)
                }}
                setModalSelectedPhase2={(selected) => {
                    setModalSelectedPhase2(selected)
                }}
                setAccessHandling={(indexAction: number) => {
                    setAccess(indexAction)
                }}
                modalSelectedPhase1={modalSelectedPhase1}
                modalSelectedPhase2={modalSelectedPhase2}
                setAllAccess={() => {
                    setAllAccess({})
                }}
            />
            <SuspendUserModal
                data={userDetailData}
                modalService={modalConfirmationService}
                isActive={userDetailData?.isActive ?? false}
                onSubmit={function (): void {
                    if (userDetailData?.isActive) {
                        deleteUser()
                    } else {
                        unDeleteUser()
                    }
                }}
            /> */}
        </div>
    )
}

const ValueLabel = ({
    label = ' - ',
    value = ' - ',
}: {
    label?: string
    value?: string
}) => {
    const labelShow = label === '' || !label ? '-' : label
    const valueShow = value === '' || !value ? '-' : value
    return (
        <div className="leading-none ">
            <p className="!text-size-XS font-light pb-1">{labelShow}</p>
            <p className="!text-size-M font-normal">{valueShow}</p>
        </div>
    )
}

export default ManageUserDetails
