/* eslint-disable no-unused-vars */
import Input from 'components/input/input.component'
import { contactTypeHeaders } from '../organisation-details.static'
import Checkbox from 'components/checkbox/checkbox.component'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import { IOrganisation } from '../../organisation.interface'
import { FormikContextType, FormikProvider } from 'formik'
import FormInput from 'components/form-input/form-input.component'
import RemoveModalAddress from '../components/remove-address-modal.component'
import { IUseModal } from 'components/modal/modal.service'

const Details = ({
    data,
    setData,
    isEdit,
    formik,
    modalService,
}: {
    data: IOrganisation
    setData: (data: IOrganisation) => void
    isEdit: boolean
    setIsEdit: (data: boolean) => void
    formik: FormikContextType<any>
    modalService: IUseModal
}) => {
    const borderComponent = <div className="border-b"></div>
    const contactTypeBorder: string[] = ['payables', 'warehouse', 'storeOrShop']

    return (
        <div className="flex gap-x-2">
            <div className="w-3/4 border rounded  h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-4">Organisation Details</div>
                {borderComponent}
                <FormikProvider value={formik}>
                    <div className="p-4 flex flex-col gap-3 overflow-auto">
                        <div className="flex gap-x-2">
                            <FormInput
                                label="Org Code"
                                name="code"
                                disabled={!isEdit}
                                parentDivClassName="w-1/6 !mb-0"
                            />
                            <div className="w-5/6">
                                <FormInput
                                    label="Full Name"
                                    name="fullName"
                                    disabled={!isEdit}
                                    parentInputClassName="w-full"
                                    parentDivClassName="!mb-0"
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <FormInput
                                label="Category"
                                name="category"
                                disabled={!isEdit}
                                parentDivClassName="w-1/6 !mb-0"
                            />
                            <Input
                                value="Business"
                                label=""
                                disabled
                                parentInputClassName="w-5/6"
                            />
                        </div>
                        <FormInput
                            label="User Group"
                            name="userGroup"
                            disabled={!isEdit}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormInput
                                    label="Email"
                                    name="email"
                                    disabled={!isEdit}
                                    icon="ri-mail-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Email Accounting"
                                    name="emailAccount"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormInput
                                    label="Phone number"
                                    name="phoneNumber"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Mobile number"
                                    name="number"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <Input
                                    value="-"
                                    label="Fax"
                                    disabled={!isEdit}
                                />
                            </div>
                            <div className="w-1/2">
                                <Input
                                    value="-"
                                    label="Website"
                                    disabled={!isEdit}
                                />
                            </div>
                        </div>
                        <Input
                            value="English"
                            label="Language"
                            disabled={!isEdit}
                        />
                        <Input
                            value="For Carrier"
                            label="Remark"
                            disabled={!isEdit}
                        />
                        {borderComponent}
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <Input
                                    value="(ID) INDONESIA"
                                    label="Government tax type"
                                    disabled={!isEdit}
                                />
                            </div>
                            <div className="w-1/2">
                                <Input
                                    value="52512351512515365"
                                    label="Tax Number"
                                    disabled={!isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </FormikProvider>
            </div>
            <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-4">Type</div>
                {borderComponent}
                <div className="p-4 overflow-auto ">
                    <ToggleSwitch
                        initialOn={data.status === 'active'}
                        label="Active"
                        onClick={() => {
                            modalService.openModalHandling()
                        }}
                    />
                    {borderComponent}
                    <div className="grid grid-cols-1 mt-1">
                        {contactTypeHeaders.map((data, idx) => (
                            <div key={`${data.selected}-${idx}`}>
                                <div className="flex justify-between w-full">
                                    <div>{data.label}</div>
                                    <div>
                                        <Checkbox onChecked={() => {}} />
                                    </div>
                                </div>
                                {contactTypeBorder.includes(data.selected) && (
                                    <div className="col-span-2 border-b my-1"></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <RemoveModalAddress
                modalService={modalService}
                type={
                    data.status === 'active' ? 'nonActiveOrg' : 'reactivateOrg'
                }
                data={data.fullName}
                onClick={() => {
                    const toggle =
                        data.status === 'active' ? 'nonActive' : 'active'
                    setData({ ...data, status: toggle })
                }}
            />
        </div>
    )
}

export default Details
