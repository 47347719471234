import { useLogin } from './login.service'
import LoginPage from './common/login-page.component'

const Login = (): JSX.Element => {
    const { client } = useLogin()
    if (client === '') {
        return <></>
    } else {
        return <LoginPage />
    }
}

export default Login
