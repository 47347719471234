import AvatarCircle from "components/avatar-circle/avatar-circle.component"
import { ITabItem } from "components/tab/tab.interface"
import { ITableColumn } from "components/table/table.interface"
import { ManageUsersDataDummy } from "./manage-users.dummy"
import { IUserActivityLogs } from "./manage-users.interface"

export const ManageUserStatusHeader: ITabItem[] = [
    {
        label: 'All Users',
        totalData: ManageUsersDataDummy.length,
        key: 'status',
        value: 'allUsers',
        className: 'brand',
    },
    {
        label: 'Active',
        totalData: ManageUsersDataDummy.filter((x: any) => x.userStatus === 'Active')
            .length,
        key: 'status',
        value: 'Active',
        className: 'green',
    },
    {
        label: 'Suspended',
        totalData: ManageUsersDataDummy.filter(
            (x: any) => x.userStatus === 'Suspended',
        ).length,
        key: 'status',
        value: 'Suspended',
        className: 'yellow',
    },
]

export const ManageUserHeader: ITableColumn[] = [
    {
        label: 'Image',
        accessor: 'userName',
        showLabel: false,
        exportColumn: false,
        sort: false,
        width: 30, // min width
        customBuild: (data) => {
            return (
                <div>
                    <AvatarCircle name={data} />
                </div>
            )
        },
    },
    {
        label: 'Name',
        accessor: 'userName',
    },
    {
        label: 'User ID',
        accessor: 'userID',
    },
    {
        label: 'Email',
        accessor: 'userEmail',
    },
    {
        label: 'Job Title',
        accessor: 'userJobTitle',
    },
    {
        label: 'Job Category',
        accessor: 'userJobCategory',
    },
    {
        label: 'Role',
        accessor: 'userRole',
    },
    {
        label: 'Organization',
        accessor: 'userOrganizationName',
    },
    {
        label: 'Status',
        accessor: 'userStatus',
    },
    {
        label: 'Last Access',
        accessor: 'userLastActive',
    },
]

export const userActivityHeaders: ITableColumn<IUserActivityLogs>[] = [
    {
        accessor: 'timestamp',
        label: 'Time',
        sort: true,
    },
    {
        accessor: 'module',
        label: 'Module',
        sort: true,
    },
    {
        accessor: 'action',
        label: 'Action',
        sort: true,
    },
    {
        accessor: 'description',
        label: 'Descriptions',
        sort: true,
    },
]