/* eslint-disable no-constant-condition */
import React from 'react'
import { IProgressionStepProps } from './progression-step.interface'
import useProgressionStep from './progression-step.service'
import './progression-step.style.css'

const ProgressionStep: React.FC<IProgressionStepProps> = ({
    steps,
    onChange,
    selectedItem,
}) => {
    const { labels, activeIndex, setActiveIndex } = useProgressionStep(
        steps,
        selectedItem,
    )

    return (
        <>
            {steps?.map((step, idx) => {
                // if current step is not first then the step before is active
                // based on the latest design
                let isActive = activeIndex === idx
                if (idx < activeIndex) {
                    isActive = true
                }

                const classActive = !isActive
                    ? 'themes-text-gray-v3'
                    : 'themes-text-brand-v5 themes-border-brand-v5'

                return (
                    <div
                        key={idx}
                        className={`flex justify-center gap-2 ${classActive}`}
                        onClick={() => {
                            setActiveIndex(idx)
                            onChange(step)
                        }}
                    >
                        {/* ---- Border Line ---- */}
                        {idx === 0 ? (
                            ''
                        ) : isActive ? (
                            <div className="w-28 mt-5 -mx-16">
                                <div className=" border-b-2 themes-border-brand-v5" />
                            </div>
                        ) : (
                            <div className="w-28 mt-5 -mx-16">
                                <div className=" border-b-2 themes-border-gray-v3" />
                            </div>
                        )}

                        {/* ---- Icon & Logo ---- */}
                        <div className="flex flex-col gap-2 justify-center items-center w-48">
                            <>
                                <i
                                    className={`
                                        ${step.icon}
                                        ${isActive ? 'themes-border-brand-v5' : ''} 
                                        text-size-M not-italic p-1 flex justify-center aspect-square border-2  
                                        rounded-full w-10 items-center
                                    `}
                                >
                                    {step.textIcon}
                                </i>
                                <span className="text-size-S font-normal">
                                    {labels[idx]}
                                </span>
                            </>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default ProgressionStep
