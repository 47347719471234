import { IResponseData } from 'common/common.interface'
import { post, put } from 'common/common.service'
import { endpoints } from 'common/endpoints'
import { ILoginValidation } from 'form-validation/common/login.validation'

export const createSite = async (payload: any) => {
    try {
        const execute = await post<IResponseData<any>, ILoginValidation>({
            endpoint: endpoints.poc.site_create,
            payload,
        })
        return execute
    } catch (error: any) {
        return error.response.data
    }
}

export const updateSite = async (siteID: string, payload: any) => {
    try {
        const execute = await put<IResponseData<any>, ILoginValidation>({
            endpoint: endpoints.poc.site_update + '/' + siteID,
            payload,
        })
        return execute
    } catch (error: any) {
        return error.response.data
    }
}
