import TabMaster from 'components/tab-master/tab-master.component'
import useContainers from './containers.service'
import { ContainerStatusHeader, ContainerSummaryDetailHeader } from './containers.static'
import { metaDummy } from './containers.dummy'
import { IContainer } from './containers.interface'
import TableExpandableDetail from 'components/table-expandable-detail/table-expandable-detail.component'
import GoogleMap from 'components/google-map/google-map.component'
import ContainersFilterOverlay from './components/containers-filter-overlay.component'


const Containers = () => {
    const {
        data,
        tabFilter,
        setTabFilter,
        filterOverlayService
    } = useContainers()

    return (
        <div className="content-full-height grid grid-cols-12 gap-4">
            <div className="col-span-8 flex flex-col container-global ">
                <TabMaster
                    items={ContainerStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Shipment ID (min 3 char)'}
                    onSearchSubmit={() => { }}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ContainersFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () => { },
                        icon: 'ri-settings-2-line',
                    }}
                />

                <TableExpandableDetail<IContainer>
                    data={data}
                    loading={false}
                    summaryDetail={ContainerSummaryDetailHeader}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    enableExport={false}
                />
            </div>
            <div className='col-span-4 themes-bg-white rounded-[10px] p-[5px] w-full sm:h-[500px] md:h-full  shadow-md '>
                <GoogleMap />
            </div>
        </div>
    )
}

export default Containers
