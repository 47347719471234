import Button from 'components/button/button.component'
import Dropdown from 'components/dropdown/dropdown.component'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { IContainers } from '../quotations.interface'

export const ContainerTableHeader: ISTColumn<IContainers>[] = [
    {
        accessor: 'count',
        label: 'Count',
    },
    {
        accessor: 'containerType',
        label: 'Container Type',
        // eslint-disable-next-line no-unused-vars
        customBuild: () => (
            <Dropdown
                options={[
                    { value: '20GP', label: '20GP' },
                    { value: '40GP', label: '40GP' },
                    { value: '40HC', label: '40HC' },
                    { value: '45HC', label: '45HC' },
                ]}
                placeholder="Select container type"
                dropDownIndicator={true}
                useBorder={false}
                className="w-[160px]"
            />
        ),
    },
    {
        accessor: 'count',
        label: 'Action',
        customBuild: () => (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => { }}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0  !h-[1.8rem] !w-[55px]`}
                />
                <Button
                    onClick={() => { }}
                    variant="default"
                    icon="ri-edit-2-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
            </div>
        ),
    },
]
