/* eslint-disable no-unused-vars */
import { useState } from 'react'
import LoginForm from './login-form.component'
import ForgotPasswordForm from './forgot-password-form.component'
import { ReactComponent as LogoLogistical } from '../../../../assets/img/icons/loglines_logo.svg'
import video from '../../../../assets/video/loginvideo.mp4'
import '../login.style.css'
const LoginPage = () => {
    const [isLoginForm, setIsLoginForm] = useState(true)

    const component = isLoginForm ? (
        <LoginForm
            forgotPasswordToggle={() => {
                setIsLoginForm(false)
            }}
        />
    ) : (
        <ForgotPasswordForm
            backFunction={() => {
                setIsLoginForm(true)
            }}
        />
    )

    return (
        <div className="login-bg font-bahnschrift">
            <video playsInline autoPlay muted loop id="bgvid">
                <source src={video} type="video/mp4" />
            </video>

            <div className="min-h-screen flex items-center  sm:ml-[16vw] mobile:ml-[16vw]  md:ml-[16vw] lg:ml-[5vw]">
                <div className="mx-auto mobile:w-full absolute sm:w-2/3 md:w-2/3 lg:w-1/3 xl:w-1/3 xl:max-w-[440px]">
                    <div className="login-container  px-10 py-10 rounded-md shadow-md">
                        {/* logo */}
                        <LogoLogistical className="themes-icon-brand login-logo mx-auto" />

                        {/* Login Form */}
                        {component}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginPage
