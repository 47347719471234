import Button from 'components/button/button.component'
import Overlay from 'components/overlay/overlay.component'
import { IUseOverlay } from 'components/overlay/overlay.service'
import SetupSiteFilter from './setup-site-filter.component'

const SetupSiteFilterOverlay = ({
    filterOverlayService,
}: {
    filterOverlayService: IUseOverlay
}) => {
    return (
        <Overlay isOverlayOpen={filterOverlayService.isOverlayOpen}>
            <div className={`w-full `}>
                <div className="flex font-bold text-size-L justify-between">
                    Filters
                    <Button
                        className="!w-4 !h-4 !border-0 mb-2"
                        icon="ri-close-fill"
                        onClick={() => {
                            filterOverlayService.closeOverlayHandling()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <SetupSiteFilter />
                <div className="border-b border-gray-40 mt-2"></div>
                <div className="w-full flex mt-2 gap-3">
                    <Button
                        onClick={async () => {}}
                        label="RESET"
                        className="w-1/4"
                        variant="red"
                    />
                    <Button
                        onClick={() => {}}
                        label="APPLY FILTER"
                        className="w-3/4"
                        variant="brand-v2"
                    />
                </div>
            </div>
        </Overlay>
    )
}

export default SetupSiteFilterOverlay
