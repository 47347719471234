import { ICardDashboard } from './card-dashboard.interface'

export default function CardsDashboard({
    className,
    children,
}: ICardDashboard) {
    return (
        <div
            className={`${className} gap-1 w-full p-[10px] themes-bg-white rounded-[10px] border border-gray-300`}
        >
            {children}
        </div>
    )
}
