/* eslint-disable no-unused-vars */
import Button from 'components/button/button.component'
import SimpleTable from 'components/simple-table/simple-table.component'
import { IContactList } from '../../organisation-details/organisation-details.interface'
import { contactListHeaders } from '../../organisation-details/organisation-details.static'
import { IUseModal } from 'components/modal/modal.service'
import RemoveModalAddress from '../../organisation-details/components/remove-address-modal.component'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import FormInput from 'components/form-input/form-input.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormTextArea from 'components/form-textarea/form-textarea.component'

const FormContact = ({
    data,
    detailsData,
    setDetailsData,
    createOrEditContactModal,
    setIsCreateAddress,
    removeModalService,
    removeData,
    addContactModalService,
    formikValues,
}: {
    data: IContactList[]
    detailsData?: IContactList
    setDetailsData: (data: IContactList) => void
    createOrEditContactModal: IUseModal
    setIsCreateAddress: (data: boolean) => void
    removeModalService: IUseModal
    removeData: () => void
    addContactModalService: IUseModal
    formikValues?: IContactList[]
}) => {
    const contact = formikValues?.find((addr) => addr.id === detailsData?.id)
    return (
        <div className="flex gap-3 h-full">
            <div className="w-3/4 flex flex-col gap-3">
                <div className="flex justify-between">
                    <div>
                        <div className="text-size-L">
                            Additional Contacts ({formikValues?.length})
                        </div>
                        <div className="text-size-XS">
                            Please select or create contacts related to this
                            organization/client.
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setIsCreateAddress(true)
                            createOrEditContactModal.openModalHandling()
                        }}
                        label="CREATE NEW CONTACT"
                        variant="brand-inverse"
                        icon="ri-add-line"
                    />
                </div>
                <SimpleTable<IContactList>
                    headers={contactListHeaders}
                    data={data}
                    addRowLabel="Add Contact"
                    isAddRow={true}
                    variant="gray"
                    onRowClick={(data) => setDetailsData(data)}
                    customBuildParam4={(data) => ({
                        func2: () => {
                            setIsCreateAddress(false)
                            createOrEditContactModal.openModalHandling()
                        },
                        func3: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        addContactModalService.openModalHandling()
                    }}
                />
            </div>
            <div className="w-1/4 border rounded flex flex-col">
                <div className="p-3">Contact Details</div>
                <div className="border-b"></div>
                {detailsData && contact && (
                    <div className="p-3 overflow-auto flex flex-col gap-3">
                        <ToggleSwitch
                            initialOn={
                                detailsData?.active !== undefined
                                    ? detailsData?.active
                                    : true
                            }
                            label="Active"
                            disabled={true}
                        />
                        <FormInput
                            label="Contact Name"
                            name={`contact[${formikValues?.indexOf(contact)}].contactName`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Contact Name"
                        />
                        <FormInput
                            label="Job Tittle"
                            name={`contact[${formikValues?.indexOf(contact)}].jobTitle`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Job Tittle"
                        />
                        <FormInput
                            label="Job Category"
                            name={`contact[${formikValues?.indexOf(contact)}].jobCategory`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Job Category"
                        />
                        <FormInput
                            label="Phone Number"
                            name={`contact[${formikValues?.indexOf(contact)}].phoneNumber`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Phone Number"
                        />
                        <FormInput
                            label="Email"
                            name={`contact[${formikValues?.indexOf(contact)}].emailContact`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Email"
                        />
                        <FormDropdown
                            name={`contact${formikValues?.indexOf(contact)}}].relatedOrganisation`}
                            label="Related Organisation"
                            placeholder="Select Related Organisation"
                            options={[
                                {
                                    label: 'English',
                                    value: 'English',
                                },
                                {
                                    label: 'Indonesian',
                                    value: 'Indonesian',
                                },
                            ]}
                        />
                        {/* need to fixing reponsive component datePicker */}
                        {/* <DatePicker isRange={false} label='Born Date' /> */}
                        <FormInput
                            label="Born Date"
                            name={`contact[${formikValues?.indexOf(contact)}].bornDate`}
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                            placeholder="Enter Born Date"
                        />
                        <FormTextArea
                            label="Working Address"
                            name={`contact[${formikValues?.indexOf(contact)}].workingAddress`}
                            placeholder="Enter Working Address"
                        />
                    </div>
                )}
            </div>

            {detailsData && (
                <RemoveModalAddress
                    modalService={removeModalService}
                    type="removeAddress"
                    onClick={() => {
                        removeData()
                    }}
                    data={detailsData.contactName}
                />
            )}
        </div>
    )
}

export default FormContact
