import { useEffect, useState } from 'react'
import Alert from 'components/alert/alert.component'
import Button from 'components/button/button.component'
import FormInput from 'components/form-input/form-input.component'
import LinkText from 'components/link-text/link-text.component'
import {
    ILoginValidation,
    loginInitialValue,
    loginValidation,
} from 'form-validation/common/login.validation'
import { Form, Formik } from 'formik'
import { useLoginService } from '../login.service'
import { removeSessionMessage } from '../login.slice'
import { useDispatch } from 'react-redux'
const LoginForm = ({
    forgotPasswordToggle,
}: {
    forgotPasswordToggle: () => void
}) => {
    const dispatch = useDispatch()
    const { submitLoginNoApi, errorMessage, loading, user } = useLoginService()

    // session expired message
    const [sessionMessage] = useState(user.sessionExpiredMessage)
    const error = errorMessage ? errorMessage : sessionMessage
    useEffect(() => {
        dispatch(removeSessionMessage())
    }, [sessionMessage])

    return (
        <>
            <div className="text-[20px] font-bold mb-3">Login</div>
            {/* Form */}
            <Formik<ILoginValidation>
                validationSchema={loginValidation}
                initialValues={loginInitialValue}
                onSubmit={(values, { setSubmitting }) => {
                    submitLoginNoApi({
                        ...values,
                        email: values.email,
                    })
                    setSubmitting(false)
                }}
            >
                <Form>
                    <FormInput
                        name="email"
                        label="EMAIL ADDRESS"
                        placeholder="Enter email address here"
                        className="w-full"
                    />
                    <FormInput
                        name="password"
                        label="PASSWORD"
                        type={'password'}
                        placeholder="Enter password here"
                        className="w-full"
                        client='JPL'
                    />
                    <LinkText
                        label="FORGOT PASSWORD"
                        onClick={() => {
                            forgotPasswordToggle()
                        }}
                    />
                    <Alert
                        className={`mt-[40px] 
                        ${error ? '' : 'opacity-0'}`}
                        label={error}
                        type="danger"
                    />
                    <Button
                        type="submit"
                        label="LOGIN TO PORTAL"
                        className="w-full !justify-start"
                        variant="brand"
                        onClick={() => { }}
                        isLoading={loading}
                    />
                </Form>
            </Formik>
        </>
    )
}
export default LoginForm
