import { ITabItem } from 'components/tab/tab.interface'

export const ContentSetupSiteForm: ITabItem[] = [
    {
        label: 'Site Details',
        key: 'status',
        value: 'siteDetails',
        className: 'brand',
    },
    {
        label: 'Dangerous Goods Details',
        key: 'status',
        value: 'dangerousGoodsDetails',
        className: 'brand',
    },
]
