import LoglinesLogo from 'assets/img/logos/LoglinesLogo.png'

const PreviewQuote = ({ data }: { data?: any }) => {
    console.log(data)

    return (
        <div className="themes-bg-white p-6 leading-10">
            <div className="flex items-center justify-between w-full">
                <img src={LoglinesLogo} alt="Loglines Logo" />
                <div className="">
                    <div className="border-b">QUOTATION</div>
                    <div className="border-b grid grid-cols-4">
                        <div className="col-span-2">Quote No.</div>
                        <div className="col-span-2">001</div>
                    </div>
                    <div className="border-b grid grid-cols-4">
                        <div className="col-span-2">Issued Date</div>
                        <div className="col-span-2">FEB 19, 2024</div>
                    </div>
                    <div className="border-b grid grid-cols-4">
                        <div className="col-span-2">Valid Till</div>
                        <div className="col-span-2">FEB 25, 2024</div>
                    </div>
                </div>
            </div>
            <div className="justify-between grid grid-cols-2 gap-10">
                <div>
                    <div className="border-b">QUOTATION BY</div>
                    <div className="border-b  grid grid-cols-6">
                        <div className="col-span-1">Company</div>
                        <div className="col-span-5">LogLines TM</div>
                    </div>
                    <div className="border-b grid grid-cols-6">
                        <div className="col-span-1">Issued by</div>
                        <div className="col-span-5">Bunga Citra</div>
                    </div>
                    <div className="grid grid-cols-6">
                        <div className="col-span-1">Address</div>
                        <div className="col-span-5">
                            Bali, Indonesia No 88 SUNSET BUREAU Jl. Sunset Road
                            No.88, Seminyak, Kec. Kujta, Kabupaten Badung, Bali
                            80361
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border-b">QUOTATION TO</div>
                    <div className="border-b grid grid-cols-6">
                        <div className="col-span-1">Customer</div>
                        <div className="col-span-5">Jane Doe</div>
                    </div>
                    <div className="border-b grid grid-cols-6">
                        <div className="col-span-1">Contact</div>
                        <div className="col-span-5">-</div>
                    </div>
                    <div className="border-b grid grid-cols-6">
                        <div className="col-span-1">Address</div>
                        <div className="col-span-5">-</div>
                    </div>
                </div>
            </div>
            <div>
                <div className="p-3 mt-20 border-t text-size-XL">
                    Quotation Details
                </div>
                <div>
                    <div className="border-b">RATE DETAILS</div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Transport Mode</div>
                        <div className="col-span-8">Air Freight</div>
                    </div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Destination</div>
                        <div className="col-span-8">DUBAI (DXB), UAE</div>
                    </div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Carrier</div>
                        <div className="col-span-8">Carrier</div>
                    </div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Schedule</div>
                        <div className="col-span-8">Update</div>
                    </div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Fuel</div>
                        <div className="col-span-8">$0.65</div>
                    </div>
                    <div className="grid grid-cols-9 border-b">
                        <div className="col-span-1">Sec Sur</div>
                        <div className="col-span-8">-</div>
                    </div>
                    <div>Rates Range</div>
                    <div className="grid grid-cols-8 border-x border-t rounded-t">
                        <div className="px-5 border-r">Weight</div>
                        <div className="px-5 border-r">+45 kg</div>
                        <div className="px-5 border-r">+100 kg</div>
                        <div className="px-5 border-r">+250 kg</div>
                        <div className="px-5 border-r">+300 kg</div>
                        <div className="px-5 border-r">+500 kg</div>
                        <div className="px-5 border-r">+1000 kg</div>
                        <div className="px-5">Com.</div>
                    </div>
                    <div className="grid grid-cols-8 border rounded-b">
                        <div className="px-5 border-r">Rate</div>
                        <div className="px-5 border-r">$3.50</div>
                        <div className="px-5 border-r">$2.90</div>
                        <div className="px-5 border-r">$2.80</div>
                        <div className="px-5 border-r">$2.15</div>
                        <div className="px-5 border-r">$2.15</div>
                        <div className="px-5 border-r">$1.95</div>
                        <div className="px-5">G/H</div>
                    </div>
                </div>
                <div className="grid grid-cols-5 border-b">
                    <div className="col-span-2">ADDITIONAL CHARGES</div>
                    <div className="col-span-1">DESCRIPTION</div>
                    <div className="col-span-2">USD</div>
                </div>
                <div className="grid grid-cols-5 border-b">
                    <div className="col-span-2">1. Export Documents</div>
                    <div className="col-span-1">(COO + Invoice/P.List)</div>
                    <div className="col-span-2">$45.00 / Shipment</div>
                </div>
                <div className="grid grid-cols-5 border-b">
                    <div className="col-span-2">
                        2. Handling and airport terminal fee
                    </div>
                    <div className="col-span-1">Minimum</div>
                    <div className="col-span-2">
                        <div>$35.00 / Shipment + 10% taxes</div>
                        <div>$0.20 /kg + 10% taxes</div>
                    </div>
                </div>
                <div className="grid grid-cols-5 border-b">
                    <div className="col-span-2">3. Packing</div>
                    <div className="col-span-1 grid grid-rows-6">
                        <div className="row-span-1">
                            Carton boxes (58x34x43 cm)
                        </div>
                        <div className="row-span-2">
                            Wooden crates (Minimum)
                        </div>
                        <div>Wrapping only</div>
                        <div className="row-span-2">
                            Cartoon sheet (Minimum)
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div>$35.00 / Box</div>
                        <div>$0.20 / Shipment</div>
                        <div>$60.00 / cbm</div>
                        <div>$1.70 / Box</div>
                        <div>$25.00 / Shipment</div>
                        <div>$25.00 / cbm / Box</div>
                    </div>
                </div>
                <div className="grid grid-cols-5 border-b">
                    <div className="col-span-2">
                        4. Export custom clearance at origin
                    </div>
                    <div className="col-span-1">-</div>
                    <div className="col-span-2">
                        <div>$25.00 / Shipment</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewQuote
