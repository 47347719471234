import { IProgressBarProps } from './progress-bar.interface'
import './progress-bar.style.css'

const ProgressBar: React.FC<IProgressBarProps> = ({
    total,
    current,
    returned = 0,
    variant,
    icon,
}) => {
    const completedProgress = (current / total) * 120 // 120 is max width
    const remaining = total - current
    const isFull = completedProgress >= 120

    console.log(completedProgress, remaining, isFull)

    return (
        <div className={`progress-bar progress-bar-${variant} `}>
            {/* Show Current Data  */}
            <div className=" flex flex-row-reverse">
                {variant === 'outline-blue' ? `` : `${current}`}
            </div>

            {/* Background Bar */}
            <div
                className={`rounded flex-1 bg-bar min-w-[120px] max-w-[120px]`}
            >
                {/* Outline */}
                <div className={`h-2 rounded overflow-hidden border-bar`}>
                    {/* Progress Bar */}
                    <div
                        className={`bg-progress top-0 left-0 h-full transition-width duration-500 ease-in-out`}
                        style={{
                            width: `${completedProgress}px`,
                        }}
                    >
                        {/* Completed Logo */}
                        {isFull && (
                            <div className="flex w-full h-full items-center justify-center z-10">
                                <i className={`${icon}`}></i>
                            </div>
                        )}
                    </div>
                    {variant === 'outline-green' && (
                        /* Returned Progress Bar */
                        <div
                            className={` top-0 left-0  h-full transition-width duration-500 ease-in-out themes-bg-red-v4`}
                            style={{
                                width: `${returned}%`,

                                marginLeft: `${completedProgress}%`,
                            }}
                        ></div>
                    )}
                </div>
            </div>

            {/* Show Remaining Data  */}
            <div className="ml-2 ">
                {variant === 'outline-blue'
                    ? `${current}/${total}`
                    : variant === 'outline-green'
                      ? `${returned}`
                      : `${remaining}`}
            </div>
        </div>
    )
}

export default ProgressBar
