import { IMeta } from 'common/common.interface'
import { ISite } from 'form-validation/poc/site.validation'

export const SetupSiteDataDummy: ISite[] = [
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '1',
        division: '60',
        inventoryLevel: 1,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        city: 'majalengka',
        state: 'jawaTimur',
        country: 'indonesia',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '2',
        division: '60',
        inventoryLevel: 2,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '3',
        division: '60',
        inventoryLevel: 3,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '4',
        division: '60',
        inventoryLevel: 4,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '5',
        division: '60',
        inventoryLevel: 5,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '6',
        division: '60',
        inventoryLevel: 6,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '7',
        division: '60',
        inventoryLevel: 7,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '8',
        division: '60',
        inventoryLevel: 8,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '9',
        division: '60',
        inventoryLevel: 9,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '10',
        division: '60',
        inventoryLevel: 10,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '11',
        division: '60',
        inventoryLevel: 11,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '12',
        division: '60',
        inventoryLevel: 12,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '13',
        division: '60',
        inventoryLevel: 13,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '14',
        division: '60',
        inventoryLevel: 14,
        status: 'Available',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
    {
        siteCode: 'CS',
        siteName: 'Central Site',
        location: 'Majalengka, Indonesia',
        siteID: '15',
        division: '60',
        inventoryLevel: 15,
        status: 'Full',
        lastUpdated: '13-DEC-22 09:32 AM',
        addressLine1: '',
        phoneNumber: 0,
        faxNumber: 0,
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    total_Items: 15,
    index_end: 15,
    index_start: 1,
    from: 1,
    to: 20,
    last_page: 1,
    per_page: 20,
    total_page: 1,
}
