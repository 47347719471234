/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import { IUseTable } from './table-expandable-detail.interface'

export function useTable<T>({
    data,
    tabFilterItem,
}: IUseTable<T>) {
    // initial
    const [generatedData, setGeneratedData] = useState<T[]>([])
    // useEffect ---------------------------------------------------------------
    useEffect(() => {
        if (!tabFilterItem) {
            return
        }
        setGeneratedData(generateData(generatedData))
    }, [])

    useEffect(() => {
        setGeneratedData(generateData(data))
    }, [data])

    // End useEffect -----------------------------------------------------------

    // additional function -----------------------------------------------------
    function generateData(dt: T[]) {
        if ((typeof dt === 'object' && !Array.isArray(dt)) || !dt) {
            return []
        }

        let dt_: T[] = dt

        if (tabFilterItem) {
            if (tabFilterItem.value === 'all') {
                dt_ = data.filter((d: any) => {
                    return d
                })
            } else {
                dt_ = data.filter((d: any) => {
                    if (d[tabFilterItem.key] === tabFilterItem.value) {
                        return d
                    }
                })
            }
        }
        return dt_
    }

    // end function -------------------------------------------------

    return {
        generatedData,
    }
}
