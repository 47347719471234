import { transportHeader } from '../shipments-details.static'
import SimpleTable from 'components/simple-table/simple-table.component'

const Routing = (data: any) => {
    console.log(data)
    const datas = [
        {
            legOrder: 1,
            transportMode: 'Sea',
            forwardingConsole: 'Parent Console A',
            waybillNumber: 'WB123456',
            vesselName: 'Ocean Queen',
            voyageFlightNo: 'Voyage001',
            portOfLoading: 'Port A',
            portOfDischarge: 'Port B',
            etd: '2024-03-10',
            eta: '2024-03-20',
            ata: '2024-03-25',
            bookingStatus: 'Confirmed',
            carrier: 'Carrier X',
        },
        {
            legOrder: 2,
            transportMode: 'Air',
            forwardingConsole: 'Parent Console B',
            waybillNumber: 'WB789012',
            vesselName: 'Air Express',
            voyageFlightNo: 'Flight002',
            portOfLoading: 'Airport X',
            portOfDischarge: 'Airport Y',
            etd: '2024-03-15',
            eta: '2024-03-25',
            ata: '2024-03-30',
            bookingStatus: 'Pending',
            carrier: 'Carrier Y',
        },
        {
            legOrder: 3,
            transportMode: 'Road',
            forwardingConsole: 'Parent Console C',
            waybillNumber: 'WB345678',
            vesselName: '',
            voyageFlightNo: '',
            portOfLoading: 'City P',
            portOfDischarge: 'City Q',
            etd: '2024-03-20',
            eta: '2024-03-30',
            ata: '',
            bookingStatus: 'Confirmed',
            carrier: 'Carrier Z',
        },
    ]
    return (
        <SimpleTable<any>
            headers={transportHeader}
            data={datas as any[]}
            title={<>Routing</>}
        />
    )
}
export default Routing
