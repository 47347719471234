import { useEffect, useState } from 'react'
import {
    IRoleModule,
    IRoleSubModule,
} from 'repository/common/interface/role.interface'
import { getRoleModule } from 'repository/common/role.repository'

export const useSelectModule = () => {
    const [roleModuleData, setRoleModuleData] = useState<IRoleModule[]>([])
    const [selectedModule, setSelectedModule] = useState<
        IRoleModule | undefined
    >({
        enable: true,
        key: 'dashboard',
        label: 'Dashboard',
        action: {
            view: true,
        },
    })
    const [selectedSubModule, setSubSelectedModule] = useState<IRoleSubModule>()

    const getModuleData = () => {
        const data = getRoleModule()
        if (!data) {
            setRoleModuleData([])
            return
        }
        setRoleModuleData(data)
    }

    const selectModuleHandling = (props: IRoleModule) => {
        setSelectedModule(props)
        setSubSelectedModule(undefined)
    }
    const selectSubModuleHandling = (props: IRoleSubModule) => {
        setSubSelectedModule(props)
    }

    const toggleModuleEnable = (props: IRoleModule) => {
        const enable = !props.enable
        const dt = [
            ...roleModuleData.map((d) => {
                if (d.key !== props.key) return d
                return { ...d, enable }
            }),
        ]
        setRoleModuleData(dt)
        if (enable) {
            const module = { ...props, enable }
            setSelectedModule(module)
        } else {
            setSelectedModule(undefined)
        }
    }

    const toggleAction = (
        action: 'create' | 'view' | 'remove' | 'edit',
        moduleKey: string,
        subModuleKey?: string,
    ) => {
        let index = 0
        let indexSubModule = 0
        const dtx = [
            ...roleModuleData.map((d, i) => {
                if (d.key !== moduleKey) return d
                if (!subModuleKey) {
                    if (d.key !== moduleKey) return d
                    let subAction = { ...d.action } as any
                    subAction[action] = !subAction[action]
                    index = i
                    return {
                        ...d,
                        action: { ...subAction },
                    }
                }

                const subModule = d.subModule ?? []
                const subModuleNew = [
                    ...subModule.map((dt, idxt) => {
                        if (dt.key !== subModuleKey) return dt
                        indexSubModule = idxt
                        let subAction = { ...dt.action } as any
                        subAction[action] = !subAction[action]
                        return {
                            ...dt,
                            action: { ...subAction },
                        }
                    }),
                ] as IRoleSubModule[]

                index = i
                return { ...d, subModule: subModuleNew }
            }),
        ]

        setSelectedModule(dtx[index])
        if (subModuleKey) {
            const subm = dtx[index].subModule as any
            const submm = subm[indexSubModule]
            setSubSelectedModule(submm)
        }
        setRoleModuleData(dtx)
    }

    useEffect(() => {
        getModuleData()
    }, [])

    return {
        selectModuleHandling,
        selectSubModuleHandling,
        toggleModuleEnable,
        toggleAction,
        roleModuleData,
        selectedSubModule,
        selectedModule,
    }
}
