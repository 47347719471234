import { requiredMessage } from 'form-validation/validation-message.static'
import * as YUP from 'yup'

export interface ISite {
    //Site Info
    siteCode: string
    siteID?: string
    siteName?: string
    location?: string
    glnCode?: string
    division?: string
    stocktakeInProgress?: string
    inventoryLevel?: number
    status?: string
    lastUpdated?: string

    //Site Address Details
    addressLine1: string
    addressLine2?: string
    city?: string
    state?: string
    country?: string
    postCode?: string

    //Site Contact
    phoneNumber: number
    faxNumber: number

    //DG Contact Information
    dgContactName?: string
    dgPhoneNumber?: string
    dgAddressLine1?: string
    dgAddressLine2?: string
}

export const createSiteValidation = YUP.object().shape({
    siteCode: YUP.string().required(requiredMessage('Site Code')),
    addressLine1: YUP.string().required(requiredMessage('Address Line 1')),
    phoneNumber: YUP.number().required(requiredMessage('Phone Number')),
    faxNumber: YUP.number().required(requiredMessage('Fax Number')),
} as Record<keyof ISite, any>)

export const createSiteInitial: ISite = {
    siteCode: '',
    addressLine1: '',
    phoneNumber: 0,
    faxNumber: 0,
}
