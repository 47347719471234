import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import { IServiceRate, IShippingMode } from './new-quote.interface'
import { INewQuote } from 'form-validation/limajari/new-quote.validation'

export const serviceRateDummy: IServiceRate[] = [
    {
        title: 'Handling',
        price: '40.000',
        unit: 'per cbm',
        qty: '1.00',
        total: '40.000',
        id: '',
    },
    {
        title: 'Packing Wooden Crate',
        price: '40.000',
        unit: 'per cbm',
        qty: '1.00',
        total: '40.000',
        id: '',
    },
]

export const shippingModes: IDropdownItem<IShippingMode>[] = [
    {
        value: '1',
        label: 'Shark Express',
        additionalData: {
            id: '1',
            rate: 'IDR 90.000 / CBM',
            sailingDate: '06 May 2024',
            transitTime: '16 days',
            forwarder: 'Shark Express',
        },
    },
    {
        value: '2',
        label: 'Shark Express',
        additionalData: {
            id: '2',
            rate: 'IDR 9.000 / CBM',
            sailingDate: '06 May 2024',
            transitTime: '16 days',
            forwarder: 'Shark Express',
        },
    },
    {
        value: '3',
        label: 'Tiger Transit',
        additionalData: {
            id: '3',
            rate: 'IDR 85.000 / CBM',
            sailingDate: '10 May 2024',
            transitTime: '14 days',
            forwarder: 'Tiger Transit',
        },
    },
]

export const portDummy = [
    {
        code: 'IDSBY',
        port: 'INDONESIA, SURABAYA',
    },
    {
        code: 'AUBRN',
        port: 'AUSTRALIA, BRISBANE',
    },
]

export const quoteDetailsDummy: INewQuote = {
    startDate: '2024-06-03T17:00:00.000Z',
    documentValidityPeriod: 'A Week',
    customerName: 'Lawren',
    transportMode: 'air',
    containerMode: 'lcl',
    etd: '2024-06-04T17:00:00.000Z',
    codeOriginPort: 'IDSBY',
    originPort: 'INDONESIA, SURABAYA',
    codeDestinationPort: 'AUBRN',
    destinationPort: 'AUSTRALIA, BRISBANE',
    currency: 'IDR - INDONESIAN RUPIAH',
    weight: {
        qty: '1',
        unit: 'KG',
    },
    volume: {
        qty: '1',
        unit: 'CBM',
    },
    shippingRates: {
        shippingMode: '',
        price: '',
        unit: '',
        qty: '',
        total: '',
    },
    serviceRates: [
        {
            title: '1',
            price: '40.000',
            unit: 'per cbm',
            qty: '1.00',
            total: '40.000',
            id: '1',
        },
        {
            title: 'Packing Wooden Crate',
            price: '40.000',
            unit: 'per cbm',
            qty: '1.00',
            total: '40.000',
            id: '2',
        },
    ],
    importantNotice: '',
}
