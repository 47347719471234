import { IDropdownItem } from "components/dropdown/dropdown.interface"

export const optionDates: IDropdownItem[] =
    [
        {
            value: 'yesterday',
            label: 'Yesterday',
        },
        {
            value: 'week',
            label: 'Last Week',
        },
        {
            value: '7D',
            label: 'Last 7 Days',
        },
        {
            value: '14D',
            label: 'Last 14 Days',
        },
        {
            value: 'last',
            label: 'Last Month',
        },
        {
            value: 'Month',
            label: 'Last Calendar Month',
        },
        {
            value: '2',
            label: 'Last 2 Mths.',
        },
        {
            value: '3',
            label: 'Last 3 Mths.',
        },
        {
            value: '6',
            label: 'Last 6 Mths.',
        },
        {
            value: '12',
            label: 'Last 12 Mths.',
        },
        {
            value: 'past',
            label: 'In the Past',
        },
    ]


export const optionDisplay: IDropdownItem[] =
    [
        {
            value: 'sea',
            label: 'SEA',
        },
        {
            value: 'land',
            label: 'LAND',
        },
        {
            value: 'air',
            label: 'AIR',
        },
    ]