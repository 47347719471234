import Button from 'components/button/button.component'
import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'
import SimpleTable from 'components/simple-table/simple-table.component'
import useQuotationForm, { IUseQuotationForm } from './quotation-form.service'
import { Toast } from 'components/toast/toast.component'
import { ContainerTableHeader } from './quotation-form.static'

const QuotationForm = ({ isNew }: IUseQuotationForm) => {
    const { navigate, containerTableActionHandling } = useQuotationForm()
    const headerLabel = isNew ? 'New Quote' : 'Quote Details'
    const buttonLabel = isNew ? 'CREATE QUOTE' : 'SAVE CHANGES'
    const toastLabel = isNew ? 'QUOTATION CREATED' : 'QUOTATION SAVED'
    const toastMessage = isNew
        ? 'Quotation successfully created'
        : 'Quotation has been updated'

    return (
        <>
            <div
                className={`booking-create flex flex-wrap gap-x-4 h-[calc(100vh-105px)] transition-opacity duration-500`}
            >
                <div className="flex-grow flex-col md:gap-y-4 gap-x-4 md:w-[calc(100vw-900px)] themes-bg-white container-border container-global overflow-x-hidden overflow-y-hidden">
                    <div className="flex w-full justify-between items-center p-4">
                        <div className="flex">
                            <span className="flex text-size-L font-bold">
                                {headerLabel}
                            </span>
                        </div>
                        <div className="flex space-x-4">
                            <Button
                                className="w-btnRegularWidth"
                                label="RESET CHANGES"
                                variant="default"
                                onClick={() => { }}
                            />
                            <Button
                                className="w-btnRegularWidth !text-size-S"
                                label={buttonLabel}
                                variant="brand"
                                onClick={() => {
                                    Toast({
                                        header: toastLabel,
                                        message: toastMessage,
                                        type: 'success',
                                    })
                                    navigate('/quotations')
                                }}
                            />
                        </div>
                    </div>
                    <div className="border-b themes-border-color" />
                    <div className="flex flex-col w-full p-4 gap-y-5 h-[calc(100vh-255px)] overflow-y-auto">
                        <div className="flex flex-col gap-y-[10px] w-full">
                            <div className="flex">
                                <span className="text-size-M font-bold">
                                    Origin{' '}
                                    <i className="ri-arrow-right-line text-size-M font-bold" />{' '}
                                    Destination
                                </span>
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <Dropdown
                                    label="ORIGIN PORT"
                                    placeholder="Select Origin Port"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="DESTINATION PORT"
                                    placeholder="Select Destination Port"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[10px] w-full">
                            <div className="flex">
                                <span className="text-size-M font-bold">
                                    Quote Details
                                </span>
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <Dropdown
                                    label="TRANSPORT MODE"
                                    placeholder="Select Transport Mode"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'Sea Freight',
                                            value: 'seaFreight',
                                        },
                                        {
                                            label: 'Air Freight',
                                            value: 'airFreight',
                                        },
                                        {
                                            label: 'Rail Freight',
                                            value: 'RailFreight',
                                        },
                                        {
                                            label: 'Road Freight',
                                            value: 'roadFreight',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="CONTAINER MODE"
                                    placeholder="Select Container Mode"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'Full Container Load (FCL)',
                                            value: 'fullContainerLoad',
                                        },
                                        {
                                            label: 'Less Container Load (LCL)',
                                            value: 'lessContainerLoad',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="SERVICE LEVEL"
                                    placeholder="Select Service Level"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'Standard',
                                            value: 'standard',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="INCOTERM"
                                    placeholder="Select Incoterm"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'Ex Works',
                                            value: 'exWorks',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <Input
                                    label="WEIGHT"
                                    placeholder="Input Weight"
                                    className="w-1/4"
                                />
                                <Dropdown
                                    label="WEIGHT UOM"
                                    placeholder="Select Weight UOM"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'KG',
                                            value: 'kg',
                                        },
                                    ]}
                                />
                                <Input
                                    label="VOLUME"
                                    placeholder="Input Volume"
                                    className="w-1/4"
                                />
                                <Dropdown
                                    label="VOLUME UOM"
                                    placeholder="Select Volume UOM"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'M3',
                                            value: 'm3',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <Input
                                    label="VALUE OF GOOD"
                                    placeholder="Input Value Of Good"
                                    className="w-1/2"
                                />
                                <Dropdown
                                    label="VALUE OF GOOD CURRENCY"
                                    placeholder="Select Currency"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'AUD',
                                            value: 'aud',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <Input
                                    label="INSURANCE VALUE"
                                    placeholder="Input Insurance Value"
                                    className="w-1/2"
                                />
                                <Dropdown
                                    label="INSURANCE VALUE CURRENCY"
                                    placeholder="Select Currency"
                                    dropDownIndicator={true}
                                    options={[
                                        {
                                            label: 'AUD',
                                            value: 'aud',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[10px] w-full">
                            <div className="flex">
                                <span className="text-size-M font-bold">
                                    Containers
                                </span>
                            </div>
                            <div className="flex space-x-[10px] w-full">
                                <SimpleTable
                                    headers={ContainerTableHeader}
                                    data={[
                                        {
                                            count: '1',
                                            containerType: '40HS',
                                        },
                                    ]}
                                    customBuildParam2={
                                        containerTableActionHandling
                                    }
                                    isAddRow={true}
                                    addRowLabel="Add new Container"
                                    addRowClick={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-b themes-border-color" />
                    <div className="flex w-full p-4 justify-between">
                        <div className="flex">
                            <Button
                                className="w-btnRegularWidth"
                                label="BACK"
                                onClick={() => {
                                    navigate('/quotations')
                                }}
                            />
                        </div>
                        <div className="flex gap-4">
                            <Button
                                className="w-btnRegularWidth text-size-S"
                                label="GET RATES"
                                onClick={() => { }}
                                variant="brand"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuotationForm
