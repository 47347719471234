import CardsDashboard from 'components/card-dashboard/card-dashboard.component'
import Progress from '../components/progress.component'
import { DashboardSummary } from './row1.dummy'
const Row1 = () => {
    return (
        <>
            {DashboardSummary.map((dashboardSummary: any, index: number) => {
                return (
                    <CardsDashboard
                        key={index}
                        className="lg:row-end-1 md:row-span-12 sm:col-span-6 overflow-hidden  pb-[10px]"
                    >
                        <Progress data={dashboardSummary} />
                    </CardsDashboard>
                )
            })}
        </>
    )
}

export default Row1
