import { ITransportationSelection } from "./transportation-selection.interface"
import useTransportationSelection from "./transportation-selection.service"

const TransportationSelection = ({ items }: ITransportationSelection) => {
    const { activeIndex, setActiveIndex } = useTransportationSelection()
    return (
        <>
            {items.map((item, index) => {
                const isActive = activeIndex === index
                const classParentActive = !isActive ? '' : 'themes-border-brand-v2 themes-bg-brand-v2'
                const classIconParentActive = !isActive ? 'border bg-opacity-40' : 'bg-opacity-40'
                const classIconActive = !isActive ? 'text-logistical-blue-ver8' : ''

                return (
                    <div
                        key={index}
                        className={`flex flex-col relative w-full items-center justify-center border rounded-logistical-radius p-3 gap-y-3 cursor-pointer ${classParentActive}`}
                        onClick={() => {
                            setActiveIndex(index)
                        }}>
                        <div className={`flex w-16 h-16 items-center justify-center rounded-full ${classIconParentActive}`}>
                            <i className={`${item.icon} text-[28px] ${classIconActive}`} />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <span className=" text-size-L font-bold">{item.label}</span>
                            <p className="text-size-M font-normal text-wrap text-center">{item.description}</p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
export default TransportationSelection

