import { ITabItem } from 'components/tab/tab.interface'
import { ITableColumn } from 'components/table/table.interface'
import { QuotationDataDummy } from './quotations.dummy'

export const QuotationStatusHeader: ITabItem[] = [
    {
        label: 'All Quotation',
        totalData: QuotationDataDummy.length,
        key: 'status',
        value: 'allQuotations',
        className: 'brand',
    },
    {
        label: 'Approved',
        totalData: QuotationDataDummy.filter(
            (x: any) => x.status === 'approved',
        ).length,
        key: 'status',
        value: 'approved',
        className: 'green',
    },
    {
        label: 'Waiting',
        totalData: QuotationDataDummy.filter((x: any) => x.status === 'waiting')
            .length,
        key: 'status',
        value: 'waiting',
        className: 'yellow',
    },
    {
        label: 'Declined',
        totalData: QuotationDataDummy.filter(
            (x: any) => x.status === 'declined',
        ).length,
        key: 'status',
        value: 'declined',
        className: 'red',
    },
]

export const QuotationSummaryHeader: ITableColumn[] = [
    {
        label: 'Quotation No.',
        accessor: 'quotationNo',
    },
    {
        label: 'Company',
        accessor: 'companyName',
    },
    {
        label: 'Start Date',
        accessor: 'startDate',
    },
    {
        label: 'End Date',
        accessor: 'endDate',
    },
    {
        label: 'One Off Shipment Origin',
        accessor: 'oneOffShipmentOrigin',
    },
    {
        label: 'One Off Shipment Destination',
        accessor: 'oneOffShipmentDestination',
    },
    {
        label: 'Total Local Self Amount',
        accessor: 'totalLocalSelfAmount',
    },
]
