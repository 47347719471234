import { IMeta } from 'common/common.interface'
import { IContactList } from './contact-list.interface'

export const ContactListDataDummy: IContactList[] = [
    {
        id: 1,
        fullName: 'Alvianto',
        jobTitle: 'Manager',
        jobCategory: 'OPS',
        email: 'alvianto@example.com',
        phoneNumber: '+12341356673',
        organisationList: [
            'tahitir',
            'balgureie',
            'pcific',
            'sarl',
            'grav',
            'dresstable',
        ],
        lastUpdate: '12/20/24 23:59',
        status: 'active',
    },
    {
        id: 2,
        fullName: 'Mary Jane',
        jobTitle: 'Front Office',
        jobCategory: 'OPS',
        email: 'mary@example.com',
        phoneNumber: '+62648937729',
        organisationList: ['sarl', 'sft', 'mariano'],
        lastUpdate: '11/29/24 16:12',
        status: 'active',
    },
    {
        id: 3,
        fullName: 'Hadijar',
        jobTitle: 'Packing',
        jobCategory: 'LEAD',
        email: 'hadjar@example.com',
        phoneNumber: '+118296527',
        organisationList: ['mariano'],
        lastUpdate: '11/06/24 08:17',
        status: 'active',
    },
    {
        id: 4,
        fullName: 'Dheolinay',
        jobTitle: 'Front Office',
        jobCategory: 'OPS',
        email: 'dheolinay@example.com',
        phoneNumber: '+1838390279',
        organisationList: ['limajari', 'balgureie', 'pcific'],
        lastUpdate: '08/19/24 19:45',
        status: 'active',
    },
    {
        id: 5,
        fullName: 'Baltaziyr',
        jobTitle: 'Warehouse',
        jobCategory: 'OPS',
        email: 'baltaziyr@example.com',
        phoneNumber: '+12341356673',
        organisationList: ['hellman', 'florence', 'daidoh'],
        lastUpdate: '12/20/24 18:13',
        status: 'active',
    },
    {
        id: 6,
        fullName: 'Made Surasa',
        jobTitle: 'Packing',
        jobCategory: 'OPS',
        email: 'made@example.com',
        phoneNumber: '+122351259',
        organisationList: ['totem', 'hyde', 'com'],
        lastUpdate: '01/04/24 19:45',
        status: 'nonActive',
    },
    {
        id: 7,
        fullName: 'Nyoman Patrriatna',
        jobTitle: 'Warehouse',
        jobCategory: 'Finance',
        email: 'nyoman@example.com',
        phoneNumber: '+6289386673',
        organisationList: ['nyoman'],
        lastUpdate: '12/20/24 18:13',
        status: 'nonActive',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
