import Button from 'components/button/button.component'
import Overlay from 'components/overlay/overlay.component'
import { IUseOverlay } from 'components/overlay/overlay.service'
import QuotationFilter from './quotation-filter.component'

const QuotationFilterOverlay = ({
    filterOverlayService,
}: {
    filterOverlayService: IUseOverlay
}) => {
    return (
        <Overlay
            isOverlayOpen={filterOverlayService.isOverlayOpen}
            className="filter flex"
        >
            <div className={`w-full`}>
                <div className="flex font-bold text-size-L justify-between items-center px-5 py-3">
                    Filters
                    <Button
                        className="!w-4 !h-4 !border-0 mb-2"
                        icon="ri-close-fill"
                        onClick={() => {
                            filterOverlayService.closeOverlayHandling()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <QuotationFilter />
                <div className="border-b border-gray-40 mt-2"></div>
                <div className="w-full flex gap-4 px-5 py-3">
                    <Button
                        onClick={async () => { }}
                        label="RESET"
                        className="w-1/4"
                        variant="default"
                    />
                    <Button
                        onClick={() => { }}
                        label="APPLY FILTER"
                        className="w-3/4"
                        variant="brand"
                    />
                </div>
            </div>
        </Overlay>
    )
}

export default QuotationFilterOverlay
